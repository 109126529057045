import { Component, OnInit, signal } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { UtilsService } from '../../shared/utils.service';
import { Income } from '../income';
import { IncomeService } from '../income.service';

@Component({
  selector: 'app-income-edit',
  standalone: true,
  imports: [FormsModule, RouterLink],
  templateUrl: './income-edit.component.html',
  styleUrl: './income-edit.component.css',
})
export class IncomeEditComponent implements OnInit {
  income = signal<Income>({} as Income);

  constructor(
    private incomeService: IncomeService,
    private utilsService: UtilsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.income.set(<Income>this.incomeService.getIncomeById(this.route.snapshot.params['id']));
  }

  onSubmit(f: NgForm) {
    if (f.status === 'VALID') {
      this.incomeService
        .updateIncome({
          ...this.utilsService.updateKeyNames(f.value, 'income-edit-'),
          id: this.income().id,
        } as Partial<Income>)
        .subscribe({
          next: () => {
            this.router.navigate(['/revenues']);
          },
          error: e => {
            throw new Error(e);
          },
        });
    }
  }
}
