import { NgStyle } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { ExternalPartnerShopService } from '../../external-partner-shop/external-partner-shop.service';
import { MechanicalItem } from '../mechanical-inventory';
import { MechanicalInventoryService } from '../mechanical-inventory.service';

@Component({
  selector: 'app-mechanical-inventory-list',
  standalone: true,
  imports: [RouterLink, NgStyle],
  templateUrl: './mechanical-inventory-list.component.html',
  styleUrl: './mechanical-inventory-list.component.css',
})
export class MechanicalInventoryListComponent implements OnInit {
  hasEmployeeScope = signal(false);
  mechanicalInventoryItems = signal<MechanicalItem[]>([]);

  constructor(
    private mechanicalInventoryService: MechanicalInventoryService,
    private authService: AuthService,
    private externalPartnerShopService: ExternalPartnerShopService
  ) {}

  ngOnInit() {
    this.externalPartnerShopService.getPartnerShops().subscribe({
      next: () => {
        this.mechanicalInventoryService.getMechanicalInventory().subscribe({
          next: mechanicalInventoryItems => {
            this.mechanicalInventoryItems.set(
              mechanicalInventoryItems.mechanicalInventoryItems.map(v => {
                return {
                  ...v,
                  externalPartnerShopName: this.externalPartnerShopService.getPartnerShop(
                    v.externalPartnerShopId
                  ).name,
                };
              })
            );
          },
        });
      },
    });
    this.hasEmployeeScope.set(this.authService.checkIfUserIsAllowed('group:employee'));
  }
}
