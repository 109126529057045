import { Routes } from '@angular/router';
import { AuthGuard } from './auth/guard.service';
import { scopeBusinessExecutorGuard } from './auth/guards/business_executor.guard';
import { scopeEmployeeGuard } from './auth/guards/employee.guard';
import { scopeManagementGuard } from './auth/guards/management.guard';
import { LoginComponent } from './auth/login/login.component';
import { BasepageComponent } from './basepage/basepage.component';
import { EmployeeAddComponent } from './employee/employee-add/employee-add.component';
import { EmployeeEditComponent } from './employee/employee-edit/employee-edit.component';
import { EmployeeItemComponent } from './employee/employee-item/employee-item.component';
import { EmployeeListComponent } from './employee/employee-list/employee-list.component';
import { EmployeeComponent } from './employee/employee.component';
import { NotFoundComponent } from './errors/not-found/not-found.component';
import { ExternalMechanicAddComponent } from './external-mechanic/external-mechanic-add/external-mechanic-add.component';
import { ExternalMechanicEditComponent } from './external-mechanic/external-mechanic-edit/external-mechanic-edit.component';
import { ExternalMechanicItemComponent } from './external-mechanic/external-mechanic-item/external-mechanic-item.component';
import { ExternalMechanicListComponent } from './external-mechanic/external-mechanic-list/external-mechanic-list.component';
import { ExternalMechanicComponent } from './external-mechanic/external-mechanic.component';
import { ExternalPartnerShopAddComponent } from './external-partner-shop/external-partner-shop-add/external-partner-shop-add.component';
import { ExternalPartnerShopEditComponent } from './external-partner-shop/external-partner-shop-edit/external-partner-shop-edit.component';
import { ExternalPartnerShopItemComponent } from './external-partner-shop/external-partner-shop-item/external-partner-shop-item.component';
import { ExternalPartnerShopListComponent } from './external-partner-shop/external-partner-shop-list/external-partner-shop-list.component';
import { ExternalPartnerShopComponent } from './external-partner-shop/external-partner-shop.component';
import { IncomeAddComponent } from './income/income-add/income-add.component';
import { IncomeEditComponent } from './income/income-edit/income-edit.component';
import { IncomeItemComponent } from './income/income-item/income-item.component';
import { IncomeListComponent } from './income/income-list/income-list.component';
import { IncomeComponent } from './income/income.component';
import { MechanicalInventoryAddComponent } from './mechanical-inventory/mechanical-inventory-add/mechanical-inventory-add.component';
import { MechanicalInventoryEditComponent } from './mechanical-inventory/mechanical-inventory-edit/mechanical-inventory-edit.component';
import { MechanicalInventoryItemComponent } from './mechanical-inventory/mechanical-inventory-item/mechanical-inventory-item.component';
import { MechanicalInventoryListComponent } from './mechanical-inventory/mechanical-inventory-list/mechanical-inventory-list.component';
import { MechanicalInventoryComponent } from './mechanical-inventory/mechanical-inventory.component';
import { OutcomeAddComponent } from './outcome/outcome-add/outcome-add.component';
import { OutcomeEditComponent } from './outcome/outcome-edit/outcome-edit.component';
import { OutcomeItemComponent } from './outcome/outcome-item/outcome-item.component';
import { OutcomeListComponent } from './outcome/outcome-list/outcome-list.component';
import { OutcomeComponent } from './outcome/outcome.component';
import { SummaryComponent } from './summary/summary.component';
import { VehicleAddComponent } from './vehicle/vehicle-add/vehicle-add.component';
import { VehicleEditComponent } from './vehicle/vehicle-edit/vehicle-edit.component';
import { VehicleItemComponent } from './vehicle/vehicle-item/vehicle-item.component';
import { VehicleListComponent } from './vehicle/vehicle-list/vehicle-list.component';
import { VehicleComponent } from './vehicle/vehicle.component';

export const routes: Routes = [
  { path: '', component: BasepageComponent, title: 'MisticD', canActivate: [AuthGuard] },
  { path: 'connexion', component: LoginComponent, title: 'Connexion' },
  {
    path: 'vehicules',
    component: VehicleComponent,
    title: 'Vehicules',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: '', component: VehicleListComponent, canActivate: [scopeEmployeeGuard] },
      { path: 'ajout', component: VehicleAddComponent, canActivate: [scopeManagementGuard] },
      {
        path: 'modification/:id',
        component: VehicleEditComponent,
        canActivate: [scopeManagementGuard],
      },
      { path: ':id', component: VehicleItemComponent, canActivate: [scopeManagementGuard] },
    ],
  },
  {
    path: 'revenues',
    component: IncomeComponent,
    title: 'Revenues',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: '', component: IncomeListComponent, canActivate: [scopeBusinessExecutorGuard] },
      { path: 'ajout', component: IncomeAddComponent, canActivate: [scopeBusinessExecutorGuard] },
      {
        path: 'modification/:id',
        component: IncomeEditComponent,
        canActivate: [scopeBusinessExecutorGuard],
      },
      { path: ':id', component: IncomeItemComponent, canActivate: [scopeBusinessExecutorGuard] },
    ],
  },
  {
    path: 'depenses',
    component: OutcomeComponent,
    title: 'Dépenses',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: '', component: OutcomeListComponent, canActivate: [scopeBusinessExecutorGuard] },
      { path: 'ajout', component: OutcomeAddComponent, canActivate: [scopeBusinessExecutorGuard] },
      {
        path: 'modification/:id',
        component: OutcomeEditComponent,
        canActivate: [scopeManagementGuard],
      },
      { path: ':id', component: OutcomeItemComponent, canActivate: [scopeBusinessExecutorGuard] },
    ],
  },
  {
    path: 'employees',
    component: EmployeeComponent,
    title: 'employees',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: '', component: EmployeeListComponent, canActivate: [scopeEmployeeGuard] },
      { path: 'ajout', component: EmployeeAddComponent, canActivate: [scopeManagementGuard] },
      {
        path: 'modification/:id',
        component: EmployeeEditComponent,
        canActivate: [scopeManagementGuard],
      },
      { path: ':id', component: EmployeeItemComponent, canActivate: [scopeEmployeeGuard] },
    ],
  },
  {
    path: 'mecaniciens-externes',
    component: ExternalMechanicComponent,
    title: 'mecaniciens-externes',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: '', component: ExternalMechanicListComponent, canActivate: [scopeEmployeeGuard] },
      {
        path: 'ajout',
        component: ExternalMechanicAddComponent,
        canActivate: [scopeBusinessExecutorGuard],
      },
      {
        path: 'modification/:id',
        component: ExternalMechanicEditComponent,
        canActivate: [scopeManagementGuard],
      },
      { path: ':id', component: ExternalMechanicItemComponent, canActivate: [scopeEmployeeGuard] },
    ],
  },
  {
    path: 'partenaires-commercants',
    component: ExternalPartnerShopComponent,
    title: 'partenaires-commercants',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: '', component: ExternalPartnerShopListComponent, canActivate: [scopeEmployeeGuard] },
      {
        path: 'ajout',
        component: ExternalPartnerShopAddComponent,
        canActivate: [scopeBusinessExecutorGuard],
      },
      {
        path: 'modification/:id',
        component: ExternalPartnerShopEditComponent,
        canActivate: [scopeBusinessExecutorGuard],
      },
      {
        path: ':id',
        component: ExternalPartnerShopItemComponent,
        canActivate: [scopeEmployeeGuard],
      },
    ],
  },
  {
    path: 'inventaire',
    component: MechanicalInventoryComponent,
    title: 'inventaire',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: '', component: MechanicalInventoryListComponent, canActivate: [scopeEmployeeGuard] },
      {
        path: 'ajout',
        component: MechanicalInventoryAddComponent,
        canActivate: [scopeBusinessExecutorGuard],
      },
      {
        path: 'modification/:id',
        component: MechanicalInventoryEditComponent,
        canActivate: [scopeBusinessExecutorGuard],
      },
      {
        path: ':id',
        component: MechanicalInventoryItemComponent,
        canActivate: [scopeEmployeeGuard],
      },
    ],
  },
  {
    path: 'bilan',
    component: SummaryComponent,
    title: 'bilan',
    canActivate: [AuthGuard, scopeBusinessExecutorGuard],
  },
  { path: '**', component: NotFoundComponent },
];
