import {Component, OnInit, signal} from '@angular/core';
import {Vehicle, VehicleType} from '../vehicle';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {VehicleService} from '../vehicle.service';
import {TranslateService} from "../../shared/translate.service";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-vehicle-item',
  standalone: true,
  imports: [RouterLink, NgClass],
  templateUrl: './vehicle-item.component.html',
  styleUrl: './vehicle-item.component.css',
})
export class VehicleItemComponent implements OnInit {
  vehicle = signal<Vehicle>({} as Vehicle);
  isLoading = signal(false)

  constructor(
    private route: ActivatedRoute,
    private vehicleService: VehicleService,
    private router: Router,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.vehicleService
      .getVehicleById(this.route.snapshot.params['id'])
      .subscribe({
        next: (response) => {
          this.vehicle.set({
            ...response,
            type: this.translateService.translateToFrench(response.type) as VehicleType,
            category: this.translateService.translateToFrench(response.category),
          });
        },
        error: (e) => {
          console.error(e);
        },
      });
  }

  onDelete(): void {
    this.isLoading.set(true)
    this.vehicleService.deleteVehicle(this.vehicle().id as string).subscribe({
      next: () => {
        this.router.navigate(['/vehicules']);
      },
      error: (e) => {
        console.error(e);
      },
    });
  }
}
