import {Component, OnInit, signal} from '@angular/core';
import {DatePipe, NgClass} from "@angular/common";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {Employee, EmployeeGroup, EmployeeJob} from "../employee";
import {EmployeeService} from "../employee.service";
import {TranslateService} from "../../shared/translate.service";

@Component({
  selector: 'app-employee-item',
  standalone: true,
  imports: [
    DatePipe,
    RouterLink,
    NgClass
  ],
  templateUrl: './employee-item.component.html',
  styleUrl: './employee-item.component.css'
})
export class EmployeeItemComponent implements OnInit {
  employee = signal<Employee>({} as Employee);
  isLoading = signal(false)

  constructor(
    private route: ActivatedRoute,
    private employeeService: EmployeeService,
    private router: Router,
    private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.employeeService.getEmployeeById(this.route.snapshot.params['id']).subscribe({
      next: (response) => {
        this.employee.set({
          ...response,
          group: this.translateService.translateToFrench(response.group) as EmployeeGroup,
          job: this.translateService.translateToFrench(response.job) as EmployeeJob,
        });
      },
      error: (e) => {
        console.error(e);

      },
    })
  }

  onDelete(): void {
    this.isLoading.set(true)
    this.employeeService.deleteEmployee(this.employee().id as string).subscribe({
      next: () => {
        this.router.navigate(['/employees']);
      },
      error: (e) => {
        console.error(e);
      },
    });
  }
}
