import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { Outcome, OutcomeList } from './outcome';

@Injectable({
  providedIn: 'root',
})
export class OutcomeService {
  currentOutcomes: OutcomeList | undefined;

  constructor(private http: HttpClient) {}

  getAllOutcomes(cursor: string | null): Observable<OutcomeList> {
    let url = `${environment.apiUrl}/outcomes`;
    if (cursor) {
      url += `?cursor=${cursor}`;
    }
    return this.http
      .get<OutcomeList>(url)
      .pipe(
        catchError((e: HttpErrorResponse) => {
          // console.error(e.error);
          return throwError(() => e);
        })
      )
      .pipe(
        tap((data: OutcomeList) => {
          this.currentOutcomes = data;
        })
      );
  }

  getOutcomeById(id: string): Outcome | undefined {
    return this.currentOutcomes?.outcomes.find(v => v.id === id);
  }

  addOutcome(outcome: Partial<Outcome>): Observable<Partial<Outcome>> {
    /**
     * Adds a new outcome to the system.
     * @param outcome the outcome to add
     */
    return this.http
      .post<Partial<Outcome>>(`${environment.apiUrl}/outcomes`, { data: [outcome] })
      .pipe(
        catchError((e: HttpErrorResponse) => {
          // console.error(e.error);
          return throwError(() => e);
        })
      );
  }

  deleteOutcome(id: string): Observable<null> {
    /**
     Deletes the outcome with the given ID.
     @param id the ID of the income to delete
     */
    return this.http.delete<null>(`${environment.apiUrl}/outcomes/${id}`).pipe(
      catchError((e: HttpErrorResponse) => {
        // console.error(e.error);
        return throwError(() => e);
      })
    );
  }

  updateOutcome(outcome: Partial<Outcome>): Observable<Partial<Outcome>> {
    return this.http
      .put<Outcome>(`${environment.apiUrl}/outcomes/${outcome.id}`, { data: outcome })
      .pipe(
        catchError((e: HttpErrorResponse) => {
          // console.error(e.error);
          return throwError(() => e);
        })
      );
  }
}
