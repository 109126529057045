import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { Employee, EmployeeList } from './employee';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  currentEmployees: EmployeeList | undefined;
  currentEmployee: Employee | undefined;
  private initialFetch = false;

  constructor(private http: HttpClient) {}

  initialFetchEmployees() {
    if (!this.initialFetch) {
      this.initialFetch = true;
      this.getAllEmployees().subscribe();
    }
  }

  addEmployee(employee: Partial<Employee>): Observable<Partial<Employee>> {
    return this.http
      .post<Partial<Employee>>(`${environment.apiUrl}/users`, { data: employee })
      .pipe(
        catchError((e: HttpErrorResponse) => {
          // console.error(e.error);
          return throwError(() => e);
        })
      );
  }

  getAllEmployees(): Observable<EmployeeList> {
    return this.http
      .get<EmployeeList>(`${environment.apiUrl}/users`)
      .pipe(
        catchError((e: HttpErrorResponse) => {
          // console.error(e.error);
          return throwError(() => e);
        })
      )
      .pipe(
        tap((data: EmployeeList) => {
          this.currentEmployees = data;
        })
      );
  }

  getEmployeeById(id: string): Observable<Employee> {
    return this.http
      .get<Employee>(`${environment.apiUrl}/users/${id}`)
      .pipe(
        catchError((e: HttpErrorResponse) => {
          // console.error(e.error);
          return throwError(() => e);
        })
      )
      .pipe(
        tap((data: Employee) => {
          this.currentEmployee = data;
        })
      );
  }

  getEmployeeNameById(id: string): string {
    const employee = this.currentEmployees?.users.find(u => u.id === id);
    if (employee) {
      return `${employee.firstname} ${employee.lastname}`;
    }
    return '';
  }

  deleteEmployee(id: string): Observable<null> {
    /**
     Deletes the employee with the given ID.
     @param id the ID of the employee to delete
     */
    return this.http.delete<null>(`${environment.apiUrl}/users/${id}`).pipe(
      catchError((e: HttpErrorResponse) => {
        // console.error(e.error);
        return throwError(() => e);
      })
    );
  }

  updateEmployee(employee: Partial<Employee>): Observable<Partial<Employee>> {
    return this.http
      .put<Partial<Employee>>(`${environment.apiUrl}/users/${employee.id}`, { data: employee })
      .pipe(
        catchError((e: HttpErrorResponse) => {
          // console.error(e.error);
          return throwError(() => e);
        })
      );
  }
}
