import { DatePipe, NgClass } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { EmployeeService } from '../../employee/employee.service';
import { TranslateService } from '../../shared/translate.service';
import { VehicleService } from '../../vehicle/vehicle.service';
import { Income } from '../income';
import { IncomeService } from '../income.service';

@Component({
  selector: 'app-income-item',
  standalone: true,
  imports: [RouterLink, NgClass, DatePipe],
  templateUrl: './income-item.component.html',
  styleUrl: './income-item.component.css',
})
export class IncomeItemComponent implements OnInit {
  income = signal<Income>({} as Income);
  isLoading = signal(false);
  vehicleName = signal('');
  employeeName = signal('');

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private incomeService: IncomeService,
    private translateService: TranslateService,
    private employeeService: EmployeeService,
    private vehicleService: VehicleService
  ) {}

  ngOnInit(): void {
    const income = <Income>this.incomeService.getIncomeById(this.route.snapshot.params['id']);
    this.income.set({
      ...income,
      type: this.translateService.translateToFrench(income.type),
    });
    this.vehicleName.set(this.vehicleService.getVehicleNameById(this.income().vehicleId));
    this.employeeName.set(this.employeeService.getEmployeeNameById(this.income().createdBy));
  }

  onDelete(): void {
    this.isLoading.set(true);
    this.incomeService.deleteIncome(this.income().id as string).subscribe({
      next: () => {
        this.router.navigate(['/revenues']);
      },
      error: e => {
        throw new Error(e);
      },
    });
  }
}
