import {Component, OnInit, signal} from '@angular/core';
import {BasepageComponent} from "../basepage/basepage.component";
import {RouterLink, RouterOutlet} from "@angular/router";
import {NgStyle} from "@angular/common";
import {SummaryService} from "./summary.service";
import {AuthService} from "../auth/auth.service";
import {Summary, SummaryItem} from "./summary";
import {VehicleService} from "../vehicle/vehicle.service";

@Component({
  selector: 'app-summary',
  standalone: true,
  imports: [
    BasepageComponent,
    RouterOutlet,
    RouterLink,
    NgStyle
  ],
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.css'
})
export class SummaryComponent implements OnInit {
  mockSummary = signal([])
  summary = signal<Partial<Summary>>({})
  summaryByTruck = signal<SummaryItem[]>([])
  summaryByWeek = signal<SummaryItem[]>([])
  summaryByTruckByWeek = signal<SummaryItem[][]>([]);
  hasManagementScope = signal(false)

  constructor(private summaryService: SummaryService, private authService: AuthService, private vehicleService: VehicleService) {
  }

  ngOnInit(): void {
    this.summaryService.getSummary().subscribe({
      next: (response) => {
        // this.mockSummary.set(response);
        this.summary.set(response)
        this.summaryByTruck.set(Object.entries(response.summaryByTruck).map(([id, val]) => {
          return {id, ...val};
        }).map(summary => {
          return {
            ...summary,
            id: this.vehicleService.getVehicleNameById(summary.id)
          }
        }))
        this.summaryByWeek.set(response.summaryByWeek)
        // this.summaryByTruckByWeek.set(response.summaryByTruckByWeek)

        this.summaryByTruckByWeek.set(response.summaryByTruckByWeek.map(summaryByWeek => {
          return Object.entries(summaryByWeek).map(([id, summaryTruck]) => {
            return {id, ...summaryTruck} as SummaryItem;
          })
            .map(summary => {
              return {
                ...summary,
                id: this.vehicleService.getVehicleNameById(summary.id as string)
              }
            })
        }))
        console.log(this.summaryByTruckByWeek())
      }
    })
    this.hasManagementScope.set(this.authService.checkIfUserIsAllowed("group:management"))
  }
}
