import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { ExternalMechanic, ExternalMechanicList } from './external-mechanic';

@Injectable({
  providedIn: 'root',
})
export class ExternalMechanicService {
  currentExternalMechanic: ExternalMechanicList = {
    cursor: '',
    externalMechanics: [],
  };

  constructor(private http: HttpClient) {}

  addExternalMechanic(
    externalMechanic: Partial<ExternalMechanic>
  ): Observable<Partial<ExternalMechanic>> {
    return this.http
      .post<
        Partial<ExternalMechanic>
      >(`${environment.apiUrl}/external_mechanics`, { data: externalMechanic })
      .pipe(catchError(this.handleError));
  }

  getAllExternalMechanics(): Observable<ExternalMechanicList> {
    return this.http.get<ExternalMechanicList>(`${environment.apiUrl}/external_mechanics`).pipe(
      catchError(this.handleError),
      tap((data: ExternalMechanicList) => {
        this.currentExternalMechanic = data;
      })
    );
  }

  getExternalMechanicById(id: string): ExternalMechanic {
    const externalMechanic = this.currentExternalMechanic.externalMechanics.find(v => v.id === id);
    if (!externalMechanic) {
      throw new Error(`External Mechanic with id ${id} not found.`);
    }
    return externalMechanic;
  }

  updateExternalMechanic(
    externalMechanic: Partial<ExternalMechanic>
  ): Observable<Partial<ExternalMechanic>> {
    return this.http
      .put<
        Partial<ExternalMechanic>
      >(`${environment.apiUrl}/external_mechanics/${externalMechanic.id}`, { data: externalMechanic })
      .pipe(catchError(this.handleError));
  }

  deleteExternalMechanic(externalMechanicId: string): Observable<null> {
    return this.http
      .delete<null>(`${environment.apiUrl}/external_mechanics/${externalMechanicId}`)
      .pipe(catchError(this.handleError));
  }

  private handleError(e: HttpErrorResponse) {
    // console.error(e.error);
    return throwError(() => e);
  }
}
