<app-basepage>
  <h1 class="title is-3">Bilan</h1>

  <h2 class="title is-4">Bilan Mensuel</h2>
  <section class="columns">
    <div class="column is-8">
      <table class="table is-bordered is-hoverable">
        <thead>
        <tr>
          <th></th>
          <th><abbr title="Revenu">Revenu</abbr></th>
          <th><abbr title="Dépense">Dépense</abbr></th>
          <th><abbr title="Total">Total</abbr></th>
        </tr>
        </thead>
        <tbody>
        <!--          @for (item of mockSummary(); track $index) {-->
          @for (item of summaryByTruck(); track $index) {
            <tr>
              <th>{{ item.id }}</th>
              <td>{{ item.totalIncomes }}</td>
              <td>-{{ item.totalOutcomes }}</td>
              <td>{{ item.balance }}</td>
            </tr>
          }
        <tr>
          <th>Dépense global</th>
          <td>0</td>
          <td>-{{ summary().totalGlobalOutcomes }}</td>
          <td>-{{ summary().totalGlobalOutcomes }}</td>
        </tr>
        <tr>
          <th>Total</th>
          <td>{{ summary().totalIncomes }}</td>
          <td>-{{ summary().totalOutcomes }}</td>
          <td>{{ summary().balance }}</td>
        </tr>
        <!--        <tr>-->
        <!--          <th>Semaine en cours</th>-->
        <!--          <td>{{ summary().totalCurrentWeekBalance }}</td>-->
        <!--          <td>{{ summary().totalCurrentWeekIncomes }}</td>-->
        <!--          <td>{{ summary().totalCurrentWeekOutcomes }}</td>-->
        <!--        </tr>-->
        <!--          }-->


        </tbody>
      </table>
    </div>
  </section>

  <h2 class="title is-4">Bilan Hebdomadaire</h2>
  <section class="columns">
    <div class="column is-8">
      <table class="table is-bordered is-hoverable">
        <thead>
        <tr>
          <th></th>
          <th><abbr title="Revenu">Revenu</abbr></th>
          <th><abbr title="Dépense">Dépense</abbr></th>
          <th><abbr title="Total">Total</abbr></th>
        </tr>
        </thead>
        <tbody>
          @for (item of summaryByWeek(); track $index) {
            <tr>
              <th>Total semaine {{ $index + 1 }}</th>
              <td>{{ item.totalIncomes }}</td>
              <td>-{{ item.totalOutcomes }}</td>
              <td>{{ item.balance }}</td>
            </tr>
            @for (item of summaryByTruckByWeek()[$index]; track item.id) {
              <tr>
                <th>{{ item.id }}</th>
                <td>{{ item.totalIncomes }}</td>
                <td>-{{ item.totalOutcomes }}</td>
                <td>{{ item.balance }}</td>
              </tr>
            }
          }
        </tbody>
      </table>
    </div>
  </section>

</app-basepage>
