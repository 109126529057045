import { NgStyle } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { ExternalPartnerShop, ExternalPartnerShopList } from '../external-partner-shop';
import { ExternalPartnerShopService } from '../external-partner-shop.service';

@Component({
  selector: 'app-external-partner-shop-list',
  standalone: true,
  imports: [RouterLink, NgStyle],
  templateUrl: './external-partner-shop-list.component.html',
  styleUrl: './external-partner-shop-list.component.css',
})
export class ExternalPartnerShopListComponent implements OnInit {
  hasEmployeeScope = signal(false);
  externalPartnerShops = signal<ExternalPartnerShop[]>([]);

  constructor(
    private externalPartnerShopService: ExternalPartnerShopService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.externalPartnerShopService.getPartnerShops().subscribe({
      next: (response: ExternalPartnerShopList) => {
        this.externalPartnerShops.set(response.externalPartnerShops);
      },
      error: e => {
        throw new Error(e);
      },
    });
    this.hasEmployeeScope.set(this.authService.checkIfUserIsAllowed('group:employee'));
  }
}
