<table class="table is-bordered is-hoverable">
  <thead>
    <tr>
      <th><abbr title="title">Nom</abbr></th>
      <th>{{ mechanicalInventenryItem().name }}</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Prix neuf</td>
      <td>{{ mechanicalInventenryItem().newPrice }} $</td>
    </tr>
    <tr>
      <td>Prix d'occasion</td>
      <td>{{ mechanicalInventenryItem().secondHandPrice }} $</td>
    </tr>
    <tr>
      <td>Commercant partenaire</td>
      <td>{{ mechanicalInventenryItem().externalPartnerShopName }}</td>
    </tr>
    <tr></tr>
    <tr>
      <td>Commentaire additionel</td>
      <td>{{ mechanicalInventenryItem().additionalComment }}</td>
    </tr>
    <tr>
      <td>Crée le</td>
      <td>{{ mechanicalInventenryItem().creationDate | date: 'dd/MM/YYYY' }}</td>
    </tr>
    <tr>
      <td>Mise à jour le</td>
      <td>{{ mechanicalInventenryItem().lastUpdateDate | date: 'dd/MM/YYYY' }}</td>
    </tr>
  </tbody>
</table>

<div class="field is-grouped">
  <p class="control">
    <button
      [routerLink]="'../modification/' + mechanicalInventenryItem().id"
      class="button is-link"
    >
      Modifier
    </button>
  </p>
  <p class="control">
    <a routerLink="../">
      <button class="button">Retour</button>
    </a>
  </p>
  <p class="control">
    <a>
      <button
        type="submit"
        id="mechanical-inventory-item-delete-btn"
        (click)="onDelete()"
        class="button is-danger"
        [disabled]="isLoading()"
        [ngClass]="{ 'is-loading': isLoading() }"
      >
        Supprimer
      </button>
    </a>
  </p>
</div>
