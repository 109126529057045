<section class="columns is-centered">
  <div class="column is-11">
    <h1 class="title">Modification d'un commercant</h1>
    <form (ngSubmit)="onSubmit(f)" #f="ngForm" autocomplete="on">
      <div class="field">
        <label for="external-partner-shop-edit-name" class="label">Nom</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Samco"
            required
            name="external-partner-shop-edit-name"
            id="external-partner-shop-edit-name"
            ngModel
            [ngModel]="externalPartnerShop().name"
            size="300"
            #externalPartnerShopEditName="ngModel"
          />
        </div>
        @if (!externalPartnerShopEditName.valid && externalPartnerShopEditName.errors?.['required'] && f.submitted) {
          <p class="help is-danger">La nom du magasin de commerce est obligatoire</p>
        }
      </div>

      <div class="field">
        <label for="external-partner-shop-edit-location" class="label">Adresse</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="9e rue limete"
            required
            name="external-partner-shop-edit-location"
            id="external-partner-shop-edit-location"
            ngModel
            [ngModel]="externalPartnerShop().location"
            size="300"
            #externalPartnerShopEditLocation="ngModel"
          />
        </div>
        @if (!externalPartnerShopEditLocation.valid && externalPartnerShopEditLocation.errors?.['required'] && f.submitted) {
          <p class="help is-danger">L'adresse du magasin de commerce est obligatoire</p>
        }
      </div>


      <div class="field">
        <label for="external-partner-shop-edit-primaryContactFirstname" class="label">Prénom du contact</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Pierre"
            required
            name="external-partner-shop-edit-primaryContactFirstname"
            id="external-partner-shop-edit-primaryContactFirstname"
            ngModel
            [ngModel]="externalPartnerShop().primaryContactFirstname"
            size="300"
            #externalPartnerShopEditFirstname="ngModel"
          />
        </div>
        @if (!externalPartnerShopEditFirstname.valid && externalPartnerShopEditFirstname.errors?.['required'] && f.submitted) {
          <p class="help is-danger">La prénom est obligatoire</p>
        }
      </div>

      <div class="field">
        <label for="external-partner-shop-edit-primaryContactLastname" class="label">Nom de famille du contact</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Pasteur"
            required
            name="external-partner-shop-edit-primaryContactLastname"
            id="external-partner-shop-edit-primaryContactLastname"
            ngModel
            [ngModel]="externalPartnerShop().primaryContactLastname"
            size="300"
            #externalPartnerShopEditLastname="ngModel"
          />
        </div>
        @if (!externalPartnerShopEditLastname.valid && externalPartnerShopEditLastname.errors?.['required'] && f.submitted) {
          <p class="help is-danger">La nom de famille est obligatoire</p>
        }
      </div>


      <div class="field">
        <label for="external-partner-shop-edit-primaryContactPhoneNumber" class="label">Numéro de téléphone du
          contact</label>
        <div class="control">
          <input
            class="input"
            type="tel"
            placeholder="e.g: +243 123 456 789"
            required
            name="external-partner-shop-edit-primaryContactPhoneNumber"
            id="external-partner-shop-edit-primaryContactPhoneNumber"
            ngModel
            [ngModel]="externalPartnerShop().primaryContactPhoneNumber"
            #externalPartnerShopEditPhoneNumber="ngModel"
            pattern="^(?:\+|)?(?:[0-9] ?-?){6,14}[0-9]$"
          />
        </div>
        @if (!externalPartnerShopEditPhoneNumber.valid && externalPartnerShopEditPhoneNumber.errors?.['required'] && f.submitted) {
          <p class="help is-danger">La numéro de téléphone est obligatoire</p>
        } @else if (!externalPartnerShopEditPhoneNumber.valid && externalPartnerShopEditPhoneNumber.errors?.['pattern'] && f.submitted) {
          <p class="help is-danger">La numéro de téléphone n'est pas valide</p>
        }
      </div>

      <div class="field is-grouped">
        <div class="control">
          <button type="submit" class="button is-link">Valider</button>
        </div>
        <p class="control">
          <a routerLink="../">
            <button class="button">Retour</button>
          </a>
        </p>
      </div>
    </form>
  </div>
</section>
