import { NgStyle } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { TranslateService } from '../../shared/translate.service';
import { ExternalMechanic, ExternalMechanicList } from '../external-mechanic';
import { ExternalMechanicService } from '../external-mechanic.service';

@Component({
  selector: 'app-external-mechanic-list',
  standalone: true,
  imports: [RouterLink, NgStyle],
  templateUrl: './external-mechanic-list.component.html',
  styleUrl: './external-mechanic-list.component.css',
})
export class ExternalMechanicListComponent implements OnInit {
  hasEmployeeScope = signal(false);
  externalMechanics = signal<ExternalMechanic[]>([]);

  constructor(
    private router: Router,
    private externalMechanicService: ExternalMechanicService,
    private authService: AuthService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.externalMechanicService.getAllExternalMechanics().subscribe({
      next: (response: ExternalMechanicList) => {
        this.externalMechanics.set(
          response.externalMechanics.map(v => {
            return {
              ...v,
              specialities: v.specialities.map(v => this.translateService.translateToFrench(v)),
            };
          }) as ExternalMechanic[]
        );
      },
      error: e => {
        throw new Error(e);
      },
    });
    this.hasEmployeeScope.set(this.authService.checkIfUserIsAllowed('group:employee'));
  }
}
