<section class="columns is-centered">
  <div class="column is-11">
    <h1 class="title">Ajout d'un véhicule</h1>
    <form (ngSubmit)="onSubmit(f)" #f="ngForm" autocomplete="on">
      <div class="field">
        <label for="vehicle-type" class="label">Type</label>
        <div class="control">
          <div class="select">
            <select [ngModel]="defaultVehicleValues().type" name="vehicle-type" id="vehicle-type">
              <option value="tipper">Benne</option>
              <option value="tractor">Tracteur</option>
              <option value="chassis">Chassis</option>
              <option value="plate">Plateau</option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <label for="vehicle-number" class="label">Numero</label>
        <div class="control">
          <input
            class="input"
            type="number"
            placeholder="Le numero du camion"
            min="1"
            required
            name="vehicle-number"
            id="vehicle-number"
            ngModel
            #number="ngModel"
          />
        </div>
        @if (!number.valid && number.errors?.['required'] && f.submitted) {
          <p class="help is-danger">Le numéro du camion obligatoire</p>
        }
      </div>

      <div class="field">
        <label for="vehicle-price" class="label">Prix d'achat</label>
        <div class="control">
          <input
            class="input"
            type="number"
            placeholder="Le numero du camion"
            min="1"
            required
            name="vehicle-price"
            id="vehicle-price"
            ngModel
            #price="ngModel"
          />
        </div>
        @if (!price.valid && price.errors?.['required'] && f.submitted) {
          <p class="help is-danger">Le prix du camion obligatoire</p>
        }
      </div>

      <div class="field">
        <label for="vehicle-buying-date" class="label">Date d'achat</label>
        <div class="control">
          <input
            class="input"
            type="date"
            placeholder="Le numero du camion"
            min="1"
            required
            name="vehicle-buying-date"
            id="vehicle-buying-date"
            ngModel
            #buyindDate="ngModel"
          />
        </div>
        @if (!buyindDate.valid && buyindDate.errors?.['required'] && f.submitted) {
          <p class="help is-danger">Le date d'achat du camion obligatoire</p>
        }
      </div>

      <div class="field">
        <label for="vehicle-currency" class="label">Devise</label>
        <div class="control">
          <div class="select">
            <select
              [ngModel]="defaultVehicleValues().currency"
              name="vehicle-currency"
              id="vehicle-currency"
            >
              <option value="eur">Euro</option>
              <option value="usd">Dollar</option>
              <option value="cdf">Franc CFA congolais</option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <label for="vehicle-axles" class="label">Essieux</label>
        <div class="control">
          <div class="select">
            <select
              [ngModel]="defaultVehicleValues().axles"
              name="vehicle-axles"
              id="vehicle-axles"
            >
              <option value="6x6">6X6</option>
              <option value="8x4">8X4</option>
              <option value="6x4">6X4</option>
              <option value="6x2">6X2</option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <label for="vehicle-brand" class="label">Marque</label>
        <div class="control">
          <div class="select">
            <select
              [ngModel]="defaultVehicleValues().brand"
              name="vehicle-brand"
              id="vehicle-brand"
            >
              <option value="mercedes">Mercedes</option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <label for="vehicle-model" class="label">Model</label>
        <div class="control">
          <div class="select">
            <select
              [ngModel]="defaultVehicleValues().model"
              name="vehicle-model"
              id="vehicle-model"
            >
              <option value="actros">Actros</option>
              <option value="arocs">Arocs</option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <label for="vehicle-category" class="label">Category</label>
        <div class="control">
          <div class="select">
            <select
              [ngModel]="defaultVehicleValues().category"
              name="vehicle-category"
              id="vehicle-category"
            >
              <option value="truck">Camion</option>
              <option value="bus">Bus</option>
              <option value="taxi">Taxi</option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <label for="vehicle-insuranceExpirationDate" class="label"
          >Date d'expiration de l'assurance</label
        >
        <div class="control">
          <input
            class="input"
            type="date"
            placeholder="01/01/2025"
            min="1"
            name="vehicle-insuranceExpirationDate"
            id="vehicle-insuranceExpirationDate"
            ngModel
          />
        </div>
      </div>

      <div class="field">
        <label for="vehicle-technicalControlExpirationDate" class="label"
          >Date d'expiration du contrôle technique</label
        >
        <div class="control">
          <input
            class="input"
            type="date"
            placeholder="01/01/2025"
            min="1"
            name="vehicle-technicalControlExpirationDate"
            id="vehicle-technicalControlExpirationDate"
            ngModel
          />
        </div>
      </div>

      <div class="field">
        <label for="vehicle-transportAuthorizationExpirationDate" class="label"
          >Date d'expiration de l'autorisation de transport</label
        >
        <div class="control">
          <input
            class="input"
            type="date"
            placeholder="01/01/2025"
            min="1"
            name="vehicle-transportAuthorizationExpirationDate"
            id="vehicle-transportAuthorizationExpirationDate"
            ngModel
            #buyindDate="ngModel"
          />
        </div>
      </div>

      <div class="field">
        <div class="control">
          <label for="vehicle-status" class="checkbox">
            <input
              ngModel
              name="vehicle-status"
              id="vehicle-status"
              type="checkbox"
              [ngModel]="defaultVehicleValues().status"
            />
            Le véhicule est il déjà en travail ?
          </label>
        </div>
      </div>

      <div class="field is-grouped">
        <div class="control">
          <button type="submit" class="button is-link">Submit</button>
        </div>
        <div class="control">
          <button class="button is-link is-light">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</section>
