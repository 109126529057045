import { Component, signal } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UtilsService } from '../../shared/utils.service';
import { Vehicle } from '../vehicle';
import { VehicleService } from '../vehicle.service';

@Component({
  selector: 'app-vehicle-add',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './vehicle-add.component.html',
  styleUrl: './vehicle-add.component.css',
})
export class VehicleAddComponent {
  defaultVehicleValues = signal({
    type: 'tipper',
    currency: 'eur',
    axles: '6x6',
    brand: 'mercedes',
    model: 'actros',
    category: 'truck',
    status: false,
  });

  constructor(
    private vehicleService: VehicleService,
    private utilSerivce: UtilsService,
    private router: Router
  ) {}

  onSubmit(f: NgForm): void {
    if (f.status === 'VALID') {
      this.vehicleService
        .addVehicle({
          ...this.utilSerivce.updateKeyNames(f.value, 'vehicle-'),
          name: this.computeVehicleName(f.value['vehicle-type'], f.value['vehicle-number']),
          buyingDate: f.value['vehicle-buying-date'],
        } as Vehicle)
        .subscribe({
          next: () => {
            this.router.navigate(['/vehicules']);
          },
          error: e => {
            throw new Error(e);
          },
        });
    }
  }

  computeVehicleName(vehicleType: string, vehicleNumber: number): string {
    let prefix: string = '';
    if (vehicleType === 'tipper') prefix = 'B';
    else if (vehicleType === 'chassis' || vehicleType === 'tractor' || vehicleType === 'plate')
      prefix = 'M';
    return `C${prefix}${vehicleNumber}`;
  }
}
