<section class="columns is-centered">
  <div class="column is-11">
    <h1 class="title">Ajout d'un mécanicien externe</h1>
    <form (ngSubmit)="onSubmit(f)" #f="ngForm" autocomplete="on">


      <div class="field">
        <label for="external-mechanic-edit-firstname" class="label">Prénom</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Pierre"
            required
            name="external-mechanic-edit-firstname"
            id="external-mechanic-edit-firstname"
            ngModel
            [ngModel]="externalMechanic().firstname"
            size="300"
            #externalMechanicEditFirstname="ngModel"
          />
        </div>
        @if (!externalMechanicEditFirstname.valid && externalMechanicEditFirstname.errors?.['required'] && f.submitted) {
          <p class="help is-danger">La prénom est obligatoire</p>
        }
      </div>

      <div class="field">
        <label for="external-mechanic-edit-lastname" class="label">Nom de famille</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Pasteur"
            required
            name="external-mechanic-edit-lastname"
            id="external-mechanic-edit-lastname"
            ngModel
            [ngModel]="externalMechanic().lastname"
            size="300"
            #externalMechanicEditLastname="ngModel"
          />
        </div>
        @if (!externalMechanicEditLastname.valid && externalMechanicEditLastname.errors?.['required'] && f.submitted) {
          <p class="help is-danger">La nom de famille est obligatoire</p>
        }
      </div>


      <div class="field">
        <label for="external-mechanic-edit-phoneNumber" class="label">Numéro de téléphone</label>
        <div class="control">
          <input
            class="input"
            type="tel"
            placeholder="e.g: +243 123 456 789"
            required
            name="external-mechanic-edit-phoneNumber"
            id="external-mechanic-edit-phoneNumber"
            ngModel
            [ngModel]="externalMechanic().phoneNumber"
            #externalMechanicEditPhoneNumber="ngModel"
            pattern="^(?:\+|)?(?:[0-9] ?-?){6,14}[0-9]$"
          />
        </div>
        @if (!externalMechanicEditPhoneNumber.valid && externalMechanicEditPhoneNumber.errors?.['required'] && f.submitted) {
          <p class="help is-danger">La numéro de téléphone est obligatoire</p>
        } @else if (!externalMechanicEditPhoneNumber.valid && externalMechanicEditPhoneNumber.errors?.['pattern'] && f.submitted) {
          <p class="help is-danger">La numéro de téléphone n'est pas valide</p>
        }
      </div>

      <div class="field">
        <div class="checkboxes">
          <label for="external-mechanic-edit-speciality-motor" class="checkbox">
            <input
              ngModel
              [ngModel]="externalMechanicSpecialities().motor"
              name="external-mechanic-edit-speciality-motor"
              id="external-mechanic-edit-speciality-motor"
              type="checkbox"
              #externalMechanicSpecialityMotor
            />
            Moteur
          </label>
          <label for="external-mechanic-edit-speciality-gearbox" class="checkbox">
            <input
              ngModel
              [ngModel]="externalMechanicSpecialities().gearbox"
              name="external-mechanic-edit-speciality-gearbox"
              id="external-mechanic-edit-speciality-gearbox"
              type="checkbox"
              #externalMechanicSpecialityGearbox
            />
            Boîte de vitesse
          </label>
          <label for="external-mechanic-edit-speciality-general" class="checkbox">
            <input
              ngModel
              [ngModel]="externalMechanicSpecialities().general"
              name="external-mechanic-edit-speciality-general"
              id="external-mechanic-edit-speciality-general"
              type="checkbox"
              #externalMechanicSpecialityGeneral
            />
            Général
          </label>
          <label for="external-mechanic-edit-speciality-air" class="checkbox">
            <input
              ngModel
              [ngModel]="externalMechanicSpecialities().air"
              name="external-mechanic-edit-speciality-air"
              id="external-mechanic-edit-speciality-air"
              type="checkbox"
              #externalMechanicSpecialityAir
            />
            Air
          </label>
        </div>
      </div>

      <div class="field">
        <label for="external-mechanic-edit-additional-comment" class="label">Description</label>
        <div class="control">
          <textarea
            class="textarea"
            type="text"
            placeholder="Commentaire sur le mécanicien"
            name="external-mechanic-edit-additional-comment"
            id="external-mechanic-edit-additional-comment"
            ngModel
            [ngModel]="externalMechanic().additionalComment"
            size="300"
            rows="10"
            #externalMechanicEditEdititionalComment="ngModel"
          ></textarea>
        </div>
      </div>


      <div class="field is-grouped">
        <div class="control">
          <button type="submit" class="button is-link">Valider</button>
        </div>
        <p class="control">
          <a routerLink="../..">
            <button class="button">Retour</button>
          </a>
        </p>
      </div>
    </form>
  </div>
</section>
