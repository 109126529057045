<section class="columns is-centered">
  <div class="column is-11">
    <h1 class="title">Ajout d'un commercant</h1>
    <form (ngSubmit)="onSubmit(f)" #f="ngForm" autocomplete="on">
      <div class="field">
        <label for="external-partner-shop-add-name" class="label">Nom</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Samco"
            required
            name="external-partner-shop-add-name"
            id="external-partner-shop-add-name"
            ngModel
            size="300"
            #externalPartnerShopAddName="ngModel"
          />
        </div>
        @if (!externalPartnerShopAddName.valid && externalPartnerShopAddName.errors?.['required'] && f.submitted) {
          <p class="help is-danger">La nom du magasin de commerce est obligatoire</p>
        }
      </div>

      <div class="field">
        <label for="external-partner-shop-add-location" class="label">Adresse</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="9e rue limete"
            required
            name="external-partner-shop-add-location"
            id="external-partner-shop-add-location"
            ngModel
            size="300"
            #externalPartnerShopAddLocation="ngModel"
          />
        </div>
        @if (!externalPartnerShopAddLocation.valid && externalPartnerShopAddLocation.errors?.['required'] && f.submitted) {
          <p class="help is-danger">L'adresse du magasin de commerce est obligatoire</p>
        }
      </div>


      <div class="field">
        <label for="external-partner-shop-add-primaryContactFirstname" class="label">Prénom du contact</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Pierre"
            required
            name="external-partner-shop-add-primaryContactFirstname"
            id="external-partner-shop-add-primaryContactFirstname"
            ngModel
            size="300"
            #externalPartnerShopAddFirstname="ngModel"
          />
        </div>
        @if (!externalPartnerShopAddFirstname.valid && externalPartnerShopAddFirstname.errors?.['required'] && f.submitted) {
          <p class="help is-danger">La prénom est obligatoire</p>
        }
      </div>

      <div class="field">
        <label for="external-partner-shop-add-primaryContactLastname" class="label">Nom de famille du contact</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Pasteur"
            required
            name="external-partner-shop-add-primaryContactLastname"
            id="external-partner-shop-add-primaryContactLastname"
            ngModel
            size="300"
            #externalPartnerShopAddLastname="ngModel"
          />
        </div>
        @if (!externalPartnerShopAddLastname.valid && externalPartnerShopAddLastname.errors?.['required'] && f.submitted) {
          <p class="help is-danger">La nom de famille est obligatoire</p>
        }
      </div>


      <div class="field">
        <label for="external-partner-shop-add-primaryContactPhoneNumber" class="label">Numéro de téléphone du
          contact</label>
        <div class="control">
          <input
            class="input"
            type="tel"
            placeholder="e.g: +243 123 456 789"
            required
            name="external-partner-shop-add-primaryContactPhoneNumber"
            id="external-partner-shop-add-primaryContactPhoneNumber"
            ngModel
            #externalPartnerShopAddPhoneNumber="ngModel"
            pattern="^(?:\+|)?(?:[0-9] ?-?){6,14}[0-9]$"
          />
        </div>
        @if (!externalPartnerShopAddPhoneNumber.valid && externalPartnerShopAddPhoneNumber.errors?.['required'] && f.submitted) {
          <p class="help is-danger">La numéro de téléphone est obligatoire</p>
        } @else if (!externalPartnerShopAddPhoneNumber.valid && externalPartnerShopAddPhoneNumber.errors?.['pattern'] && f.submitted) {
          <p class="help is-danger">La numéro de téléphone n'est pas valide</p>
        }
      </div>

      <div class="field is-grouped">
        <div class="control">
          <button type="submit" class="button is-link">Valider</button>
        </div>
        <p class="control">
          <a routerLink="../">
            <button class="button">Retour</button>
          </a>
        </p>
      </div>
    </form>
  </div>
</section>
