<section class="columns">
  <div class="column is-8">
    <table class="table is-bordered is-hoverable">
      <thead>
        <tr>
          <th><abbr title="Name">Nom</abbr></th>
          <th><abbr title="PhoneNumber">Adresse</abbr></th>
          <th><abbr title="Specialities">Nom du contact</abbr></th>
          <th><abbr title="Specialities">Téléphone du contact</abbr></th>
        </tr>
      </thead>
      <tbody>
        @for (item of externalPartnerShops(); track $index) {
          <tr>
            <th>
              <a
                [routerLink]="item.id"
                routerLinkActive="router-link-active"
                [ngStyle]="{ pointerEvents: !hasEmployeeScope() ? 'none' : null }"
              >
                {{ item.name }}</a
              >
            </th>
            <td>{{ item.location }}</td>
            <td>{{ item.primaryContactFirstname }} {{ item.primaryContactLastname }}</td>
            <td>{{ item.primaryContactPhoneNumber }}</td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</section>
