import { Component, OnInit, signal } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { ExternalPartnerShop } from '../../external-partner-shop/external-partner-shop';
import { ExternalPartnerShopService } from '../../external-partner-shop/external-partner-shop.service';
import { UtilsService } from '../../shared/utils.service';
import { VehicleService } from '../../vehicle/vehicle.service';
import { MechanicalItem } from '../mechanical-inventory';
import { MechanicalInventoryService } from '../mechanical-inventory.service';

@Component({
  selector: 'app-mechanical-inventory-add',
  standalone: true,
  imports: [FormsModule, RouterLink],
  templateUrl: './mechanical-inventory-add.component.html',
  styleUrl: './mechanical-inventory-add.component.css',
})
export class MechanicalInventoryAddComponent implements OnInit {
  currentPartnerShops = signal<ExternalPartnerShop[]>([]);
  defaultMechanicalInventoryItemAddValues = signal({
    currency: 'usd',
    vehicleId: '',
    externalPartnerShopId: '',
  });

  constructor(
    private mechanicalInventoryService: MechanicalInventoryService,
    private vehicleService: VehicleService,
    private externalPartnerShop: ExternalPartnerShopService,
    private utilsService: UtilsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.externalPartnerShop.getPartnerShops().subscribe({
      next: data => {
        this.currentPartnerShops.set(data.externalPartnerShops);
        this.defaultMechanicalInventoryItemAddValues.set({
          ...this.defaultMechanicalInventoryItemAddValues(),
          externalPartnerShopId: data.externalPartnerShops[0].id,
        });
      },
      error: error => {
        throw new Error('Error fetching partner shops:', error);
      },
    });
  }

  onSubmit(f: NgForm): void {
    if (f.status === 'VALID') {
      this.mechanicalInventoryService
        .addMechanicalInventoryItem({
          ...this.utilsService.updateKeyNames(f.value, 'mechanical-inventory-item-add-'),
        } as Partial<MechanicalItem>)
        .subscribe({
          next: () => {
            this.router.navigate(['/inventaire']);
          },
          error: e => {
            throw new Error(e);
          },
        });
    } else {
      this.utilsService.showFormErrors(f);
    }
  }
}
