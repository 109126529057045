<table class="table is-bordered is-hoverable">
  <thead>
    <tr>
      <th><abbr title="Name">Nom</abbr></th>
      <th>type</th>
      <th><abbr title="status">Statut</abbr></th>
      <th><abbr title="Drawn">Marque</abbr></th>
      <th><abbr title="Lost">Model</abbr></th>
    </tr>
  </thead>
  <tbody>
    @for (item of vehicles(); track $index) {
      <tr>
        <th>
          <a
            [routerLink]="item.id"
            routerLinkActive="router-link-active"
            [ngStyle]="{ pointerEvents: !hasManagementScope() ? 'none' : null }"
            >{{ item.name }}</a
          >
        </th>
        <td>
          {{ item.type }}
        </td>
        <td>
          <span
            [class]="item.status ? 'has-text-success gg-check-o' : 'has-text-danger gg-close-o'"
          ></span>
        </td>
        <td>{{ item.brand }}</td>
        <td>{{ item.model }}</td>
      </tr>
    }
  </tbody>
</table>
