<table class="table is-bordered is-hoverable">
  <thead>
    <tr>
      <th><abbr title="Name">Nom</abbr></th>
      <th>{{ vehicle().name }}</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>statut</td>
      <td>
        <span
          [class]="vehicle().status ? 'has-text-success gg-check-o' : 'has-text-danger gg-close-o'"
        ></span>
      </td>
    </tr>
    <tr>
      <td>marque</td>
      <td>{{ vehicle().brand }}</td>
    </tr>
    <tr>
      <td>model</td>
      <td>{{ vehicle().model }}</td>
    </tr>
    <tr>
      <td>type</td>
      <td>{{ vehicle().type }}</td>
    </tr>
    <tr>
      <td>price</td>
      <td>{{ vehicle().price }}</td>
    </tr>
    <tr>
      <td>Configuration roues</td>
      <td>{{ vehicle().axles }}</td>
    </tr>
    <tr>
      <td>Categorie</td>
      <td>{{ vehicle().category }}</td>
    </tr>
    <tr>
      <td>Date d'achat</td>
      <td>{{ vehicle().buyingDate | date: 'dd-MM-yyyy' }}</td>
    </tr>
    <tr>
      <td>Date d'expiration d'assurance</td>
      <td>{{ vehicle().insuranceExpirationDate | date: 'dd-MM-yyyy' }}</td>
    </tr>
    <tr>
      <td>Date d'expiration d'autorisation de transport</td>
      <td>{{ vehicle().transportAuthorizationExpirationDate | date: 'dd-MM-yyyy' }}</td>
    </tr>
    <tr>
      <td>Date d'expiration du contrôle technique</td>
      <td>{{ vehicle().technicalControlExpirationDate | date: 'dd-MM-yyyy' }}</td>
    </tr>
  </tbody>
</table>

<div class="field is-grouped">
  <p class="control">
    <button [routerLink]="'../modification/' + vehicle().id" class="button is-link">
      Modifier
    </button>
  </p>
  <p class="control">
    <a routerLink="../">
      <button class="button">Retour</button>
    </a>
  </p>
  <p class="control">
    <a>
      <button
        type="submit"
        id="vehicle-item-delete-btn"
        (click)="onDelete()"
        class="button is-danger"
        [disabled]="isLoading()"
        [ngClass]="{ 'is-loading': isLoading() }"
      >
        Supprimer
      </button>
    </a>
  </p>
</div>
