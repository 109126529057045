<section class="columns is-centered">
  <div class="column is-11">
    <h1 class="title">Ajout d'un revenu</h1>
    <form (ngSubmit)="onSubmit(f)" #f="ngForm" autocomplete="on">
      <div class="field">
        <label for="outcome-add-type" class="label">Type</label>
        <div class="control">
          <div class="select">
            <select
              ngModel
              [(ngModel)]="defaultOutcomeAddValues().type"
              name="outcome-add-type"
              id="outcome-add-type"
            >
              @for (item of defaultOutcomeTypes(); track $index) {
                <option [value]="item.id">{{ item.value }}</option>
              }
            </select>
          </div>
        </div>
      </div>

      @if (defaultOutcomeAddValues().type === "vehicle") {
        <div class="field">
          <label for="outcome-add-vehicleId" class="label">Vehicules</label>
          <div class="control">
            <div class="select">
              <select
                ngModel
                [(ngModel)]="defaultOutcomeAddValues().vehicleId"
                required
                name="outcome-add-vehicleId"
                id="outcome-add-vehicleId"
              >
                @for (item of defaultOutcomeAddValues().vehicleIds; track $index) {
                  <option [value]="item.id">{{ item.name }}</option>
                }
              </select>
            </div>
          </div>
        </div>
      }

      @if (defaultOutcomeAddValues().type === "salary") {
        <div class="field">
          <label for="outcome-add-employeeId" class="label">Salaire</label>
          <div class="control">
            <div class="select">
              <select
                ngModel
                [ngModel]="defaultEmployeeId()"
                required
                name="outcome-add-employeeId"
                id="outcome-add-employeeId"
              >
                @for (item of defaultEmployeesIds(); track $index) {
                  <option [value]="item.id">{{ item.firstname }} {{ item.lastname }}</option>
                }
              </select>
            </div>
          </div>
        </div>
      }


      <div class="field">
        <label for="outcome-add-amount" class="label">Quantité</label>
        <div class="control">
          <input
            class="input"
            type="number"
            placeholder="Quantité de $"
            min="0"
            required
            name="outcome-add-amount"
            id="outcome-add-amount"
            ngModel
            #outcomeAddAmount="ngModel"
          />
        </div>
        @if (!outcomeAddAmount.valid && outcomeAddAmount.errors?.['required'] && f.submitted) {
          <p class="help is-danger">La quantité de revenu est obligatoire</p>
        }
      </div>

      <div class="field">
        <label for="outcome-add-currency" class="label">Devise</label>
        <div class="control">
          <div class="select">
            <select
              [ngModel]="defaultOutcomeAddValues().currency"
              name="outcome-add-currency"
              id="outcome-add-currency"
            >
              <option value="eur">Euro</option>
              <option value="usd">Dollar</option>
              <option value="cdf">Franc CFA congolais</option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <label for="outcome-add-description" class="label">Description</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="La description des revenues"
            required
            name="outcome-add-description"
            id="outcome-add-description"
            ngModel
            size="300"
            #outcomeAddDescription="ngModel"
          />
        </div>
        @if (!outcomeAddDescription.valid && outcomeAddDescription.errors?.['required'] && f.submitted) {
          <p class="help is-danger">La description est obligatoire</p>
        }
      </div>

      <div class="field">
        <label for="outcome-add-user" class="label">Crée par</label>
        <div class="control">
          <input
            class="input"
            type="text"
            required
            name="outcome-add-user"
            id="outcome-add-user"
            ngModel
            disabled
            [ngModel]="defaultUsername()"
            #outcomeAddUser="ngModel"
          />
        </div>
        @if (!outcomeAddUser.valid && outcomeAddUser.errors?.['required'] && f.submitted) {
          <p class="help is-danger">Un nom utilisateur est obligatoire</p>
        }
      </div>

      <div class="field">
        <div class="control">
          <label for="outcome-add-isPaid" class="checkbox">
            <input
              ngModel
              name="outcome-add-isPaid"
              id="outcome-add-isPaid"
              type="checkbox"
              [ngModel]="defaultOutcomeAddValues().isPaid"
            />
            La dépense a t-elle déjà été payé ?
          </label>
        </div>
      </div>

      <div class="field is-grouped">
        <div class="control">
          <button type="submit" class="button is-link">Valider</button>
        </div>
        <p class="control">
          <a routerLink="../">
            <button class="button">Retour</button>
          </a>
        </p>
      </div>
    </form>
  </div>
</section>
