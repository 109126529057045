import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { Vehicle, VehicleList } from './vehicle';

@Injectable({
  providedIn: 'root',
  // useFactory: bearerTokenInterceptor,
})
export class VehicleService {
  private currentVehicle: Vehicle | null = null;
  private currentVehicles: Vehicle[] | undefined;
  private initialFetch = false;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {}

  getCurrentVehicle(): Vehicle | null {
    return this.currentVehicle;
  }

  initialFetchVehicles() {
    if (!this.initialFetch) {
      this.initialFetch = true;
      this.getAllVehicles().subscribe();
    }
  }

  addVehicle(vehicle: Vehicle): Observable<Vehicle> {
    return this.http.post<Vehicle>(`${environment.apiUrl}/vehicles`, { data: vehicle }).pipe(
      catchError((e: HttpErrorResponse) => {
        // console.error(e.error);
        return throwError(() => e);
      })
    );
  }

  updateVehicle(vehicle: Vehicle): Observable<Vehicle> {
    return this.http
      .put<Vehicle>(`${environment.apiUrl}/vehicles/${vehicle.id}`, {
        data: vehicle,
      })
      .pipe(
        catchError((e: HttpErrorResponse) => {
          // console.error(e.error);
          return throwError(() => e);
        })
      );
  }

  getAllVehicles(): Observable<VehicleList> {
    return this.http
      .get<VehicleList>(`${environment.apiUrl}/vehicles`)
      .pipe(
        catchError((e: HttpErrorResponse) => {
          // console.error(e.error);
          return throwError(() => e);
        })
      )
      .pipe(
        tap((data: VehicleList) => {
          this.currentVehicles = data.vehicles;
        })
      );
  }

  getVehicleById(id: string): Observable<Vehicle> {
    return this.http
      .get<Vehicle>(`${environment.apiUrl}/vehicles/${id}`)
      .pipe(
        catchError((e: HttpErrorResponse) => {
          // console.error(e.error);
          return throwError(() => e);
        })
      )
      .pipe(
        tap((data: Vehicle) => {
          this.currentVehicle = data;
        })
      );
  }

  getVehicleNameById(id: string): string {
    return this.currentVehicles?.find(v => v.id === id)?.name || '';
  }

  deleteVehicle(id: string): Observable<null> {
    /**
     Deletes the vehicle with the given ID.
     @param id the ID of the vehicle to delete
     */
    return this.http.delete<null>(`${environment.apiUrl}/vehicles/${id}`).pipe(
      catchError((e: HttpErrorResponse) => {
        // console.error(e.error);
        return throwError(() => e);
      })
    );
  }
}
