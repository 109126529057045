import {Component, OnInit, signal} from '@angular/core';
import {RouterLink} from '@angular/router';
import {AuthService} from "../../auth/auth.service";

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.css'
})
export class MenuComponent implements OnInit {
  hasBusinessExecutorScope = signal(false)
  hasManagementScope = signal(false)

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    this.hasBusinessExecutorScope.set(this.authService.checkIfUserIsAllowed("group:business_executor"))
    this.hasManagementScope.set(this.authService.checkIfUserIsAllowed("group:management"))
  }
}
