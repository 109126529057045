import { DatePipe, NgStyle } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { EmployeeService } from '../../employee/employee.service';
import { Outcome } from '../outcome';
import { OutcomeService } from '../outcome.service';

@Component({
  selector: 'app-outcome-list',
  standalone: true,
  imports: [RouterLink, NgStyle, DatePipe],
  templateUrl: './outcome-list.component.html',
  styleUrl: './outcome-list.component.css',
})
export class OutcomeListComponent implements OnInit {
  hasManagementScope = signal(false);
  outcomes = signal<Outcome[]>([]);
  cursor = signal<string>('');
  disableCursor = signal<boolean>(false);

  constructor(
    private outcomeService: OutcomeService,
    private authService: AuthService,
    private employeeService: EmployeeService
  ) {}

  ngOnInit() {
    this.outcomeService.getAllOutcomes(null).subscribe({
      next: response => {
        this.outcomes.set(
          response.outcomes.map(outcome => {
            return {
              ...outcome,
              createdBy: this.employeeService.getEmployeeNameById(outcome.createdBy),
            };
          })
        );
        this.cursor.set(response.cursor);
      },
      error: e => {
        throw new Error(e);
      },
    });
    this.hasManagementScope.set(this.authService.checkIfUserIsAllowed('group:business_executor'));
  }

  onClickGetNextOutcomes() {
    this.outcomeService.getAllOutcomes(this.cursor()).subscribe({
      next: response => {
        if (response.outcomes.length === 0) {
          this.disableCursor.set(true);
        } else {
          this.outcomes.set([
            ...this.outcomes(),
            ...response.outcomes.map(outcome => {
              return {
                ...outcome,
                createdBy: this.employeeService.getEmployeeNameById(outcome.createdBy),
              };
            }),
          ]);
          this.cursor.set(response.cursor);
        }
      },
      error: e => {
        throw new Error(e);
      },
    });
    this.hasManagementScope.set(this.authService.checkIfUserIsAllowed('group:business_executor'));
  }
}
