<section class="columns">
  <div class="column is-8">
    <table class="table is-bordered is-hoverable">
      <thead>
        <tr>
          <th><abbr title="Name">Nom</abbr></th>
          <th><abbr title="Job">Poste</abbr></th>
          <th><abbr title="Is Active">Actif</abbr></th>
        </tr>
      </thead>
      <tbody>
        @for (item of employees(); track $index) {
          <tr>
            <th>
              <a
                [routerLink]="item.id"
                routerLinkActive="router-link-active"
                [ngStyle]="{ pointerEvents: !hasManagementScope() ? 'none' : null }"
              >
                {{ item.firstname }} {{ item.lastname }}
              </a>
            </th>
            <td>{{ item.job }}</td>
            <td>
              <span
                [class]="
                  item.isActive ? 'has-text-success gg-check-o' : 'has-text-danger gg-close-o'
                "
              ></span>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</section>
