<section class="columns is-centered">
  <div class="column is-half">
    <h1 class="title">Page de connexion</h1>
    <form (ngSubmit)="onSubmit(f)" #f="ngForm" autocomplete="on">
      <div class="field">
        <label for="username" class="label">Nom Utilisateur</label>
        <div class="control">
          <input #username="ngModel" ngModel name="username" id="username" class="input" type="text"
                 placeholder="misticd" required="true">
        </div>
        @if (!username.valid && username.errors?.['required'] && f.submitted) {
          <p class="help is-danger">Le nom utilisateur est obligatoire</p>
        }
      </div>

      <div class="field">
        <label for="password" class="label">Mot de passe</label>
        <div class="control">
          <input #password="ngModel" ngModel class="input" name="password" id="password" type="password"
                 placeholder="****" required>
        </div>
        @if (!password.valid && password.errors?.['required'] && f.submitted) {
          <p class="help is-danger">Le mot de passe est obligatoire</p>
        }
      </div>


      <div class="field is-grouped">
        <div class="control">
          <button type="submit" class="button is-link" [disabled]="isLoading()" [ngClass]="{'is-loading': isLoading()}">
            Valider
          </button>
        </div>
      </div>
    </form>

    @if (loginError()) {
      <div class="notification is-danger is-light">
        <button class="delete"><span class="has-text-danger gg-close-o"></span></button>
        {{ loginError() }}
      </div>
    }
  </div>
</section>
