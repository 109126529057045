import {Component} from '@angular/core';
import {BasepageComponent} from '../basepage/basepage.component';
import {RouterOutlet} from '@angular/router';


@Component({
  selector: 'app-vehicle',
  standalone: true,
  imports: [BasepageComponent, RouterOutlet],
  templateUrl: './vehicle.component.html',
  styleUrl: './vehicle.component.css',
  providers: []
})
export class VehicleComponent {


}
