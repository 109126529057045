<footer class="footer">
  <div class="content has-text-centered columns">
    <p class="column">
      <strong>Mistic D</strong> <br />
      Filliale: Transport
    </p>
    <p class="column">
      Ville: Kinshasa <br />
      Province: Kinshasa <br />
      Adresse: 113 Bis, av depot C/GOMBE
    </p>
    <p class="column">
      <a href="mailto:misticdsarl&#64;gmail.com">Email: misticdsarl&#64;gmail.com</a>
      <br />
      Telephone: +243 897 306 446
    </p>
  </div>
</footer>
