import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { BasepageComponent } from '../basepage/basepage.component';

@Component({
  selector: 'app-employee',
  standalone: true,
  imports: [BasepageComponent, RouterOutlet],
  templateUrl: './employee.component.html',
  styleUrl: './employee.component.css',
})
export class EmployeeComponent {}
