import {Component, OnInit, signal, WritableSignal} from '@angular/core';
import {FormsModule, NgForm} from "@angular/forms";
import {Router, RouterLink} from "@angular/router";
import {VehicleService} from "../../vehicle/vehicle.service";
import {Income} from "../income";
import {AuthService} from "../../auth/auth.service";
import {UtilsService} from "../../shared/utils.service";
import {IncomeService} from "../income.service";
import {EmployeeService} from "../../employee/employee.service";

@Component({
  selector: 'app-income-add',
  standalone: true,
  imports: [
    FormsModule,
    RouterLink
  ],
  templateUrl: './income-add.component.html',
  styleUrl: './income-add.component.css'
})
export class IncomeAddComponent implements OnInit {


  defaultIncomeAddValues: WritableSignal<Partial<Income>> = signal({
    currency: 'usd',
    type: 'sand_quarry',
    vehicleIds: []
  })
  defaultUsername = signal("")

  constructor(private router: Router, private incomeService: IncomeService, private vehicleService: VehicleService, private authService: AuthService, private utilsService: UtilsService, private employeeService: EmployeeService) {
  }

  ngOnInit() {
    this.vehicleService.getAllVehicles().subscribe({
      next: (response) => {
        this.defaultIncomeAddValues.set({
          ...this.defaultIncomeAddValues(),
          vehicleIds: response.vehicles.map(v => {
            return {
              name: v.name,
              id: v.id
            }
          }),
          createdBy: this.authService.sessionCredentials.userId,
          vehicleId: response.vehicles.find(v => v)?.id
        });
        this.defaultUsername.set(this.employeeService.getEmployeeNameById(this.authService.sessionCredentials.userId || ""))
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  private computeIncomeTitle(vehicleName: string | undefined): (string | undefined) {
    if (vehicleName !== undefined) {
      return `revenu ${vehicleName}`
    }
    return undefined
  }

  onSubmit(f: NgForm) {
    if (f.status === 'VALID') {
      const vehicleIds = this.defaultIncomeAddValues()!.vehicleIds
      const user = this.defaultIncomeAddValues()!.createdBy
      if (vehicleIds && user) {
        this.incomeService.addIncome({
          ...this.utilsService.updateKeyNames(f.value, 'income-add-'),
          title: this.computeIncomeTitle(vehicleIds.find(v => v.id === f.value['income-add-vehicleId'])?.name),
          createdBy: user
        } as Partial<Income>).subscribe({
          next: () => {
            this.router.navigate(['/revenues']);
          },
          error: (e) => {
            console.error(e);
          },
        })
      }
    }
  }
}
