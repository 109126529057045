import {Component, OnInit, signal} from '@angular/core';
import {DatePipe, NgClass} from "@angular/common";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {ExternalPartnerShop} from "../external-partner-shop";
import {ExternalPartnerShopService} from "../external-partner-shop.service";

@Component({
  selector: 'app-external-partner-shop-item',
  standalone: true,
  imports: [
    DatePipe,
    RouterLink,
    NgClass
  ],
  templateUrl: './external-partner-shop-item.component.html',
  styleUrl: './external-partner-shop-item.component.css'
})
export class ExternalPartnerShopItemComponent implements OnInit {
  isLoading = signal(false)
  externalPartnerShop = signal<ExternalPartnerShop>({} as ExternalPartnerShop)

  constructor(
    private externalPartnerShopService: ExternalPartnerShopService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    // Fetch the external partner shop data from the API and update the signal
    this.externalPartnerShop.set(this.externalPartnerShopService.getPartnerShop(this.route.snapshot.params['id']));
  }

  onDelete(): void {
    this.isLoading.set(true)
    this.externalPartnerShopService.deletePartnerShop(this.externalPartnerShop()!.id)
      .subscribe({
        next: () => {
          this.router.navigate(['/partenaires-commercants']);
        },
        error: (e) => {
          console.error(e);
        },
      })
  }
}
