import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterOutlet} from '@angular/router';
import {NavComponent} from './shared/nav/nav.component';
import {FooterComponent} from './shared/footer/footer.component';
import {MenuComponent} from './shared/menu/menu.component';
import {HomepageComponent} from './homepage/homepage.component';
import {AuthService} from './auth/auth.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, NavComponent, FooterComponent, MenuComponent, HomepageComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    this.authService.getStoredCredentials()
  }
}
