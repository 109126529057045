<table class="table is-bordered is-hoverable">
  <thead>
    <tr>
      <th><abbr title="title">Intitulé</abbr></th>
      <th>{{ income().title }}</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Revenu</td>
      <td>{{ income().amount }}</td>
    </tr>
    @if (income().type !== 'other') {
      <tr>
        <td>Vehicle</td>
        <td>{{ vehicleName() }}</td>
      </tr>
    }
    <tr>
      <td>Description</td>
      <td>{{ income().description }}</td>
    </tr>
    <tr>
      <td>Créer par</td>
      <td>{{ employeeName() }}</td>
    </tr>
    <tr>
      <td>Type</td>
      <td>{{ income().type }}</td>
    </tr>
    <tr>
      <td>Crée le</td>
      <td>{{ income().creationDate | date: 'dd/MM/YYYY' }}</td>
    </tr>
    <tr>
      <td>Mise à jour le</td>
      <td>{{ income().lastUpdateDate | date: 'dd/MM/YYYY' }}</td>
    </tr>
  </tbody>
</table>

<div class="field is-grouped">
  <p class="control">
    <button [routerLink]="'../modification/' + income().id" class="button is-link">Modifier</button>
  </p>
  <p class="control">
    <a routerLink="../">
      <button class="button">Retour</button>
    </a>
  </p>
  <p class="control">
    <a>
      <button
        type="submit"
        id="income-item-delete-btn"
        (click)="onDelete()"
        class="button is-danger"
        [disabled]="isLoading()"
        [ngClass]="{ 'is-loading': isLoading() }"
      >
        Supprimer
      </button>
    </a>
  </p>
</div>
