import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Injectable, Provider} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {environment} from '../../environments/environment';

@Injectable()
export class BearerTokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    console.log(req.url.replace(environment.apiUrl, ''))
    if (!['/session'].includes(req.url.replace(environment.apiUrl, ''))) {
      return next.handle(
        req.clone({
          setHeaders: {
            Authorization: `Bearer ${this.authService.sessionCredentials.accessToken}`,
          },
        })
      );
    }
    return next.handle(req);
  }
}

export const bearerTokenInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: BearerTokenInterceptor,
  multi: true,
};
