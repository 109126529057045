import {Component, OnInit, signal} from '@angular/core';
import {DatePipe, NgClass} from "@angular/common";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {Outcome, OutcomeType} from "../outcome";
import {TranslateService} from "../../shared/translate.service";
import {OutcomeService} from "../outcome.service";
import {EmployeeService} from "../../employee/employee.service";

@Component({
  selector: 'app-outcome-item',
  standalone: true,
  imports: [
    DatePipe,
    RouterLink,
    NgClass
  ],
  templateUrl: './outcome-item.component.html',
  styleUrl: './outcome-item.component.css'
})
export class OutcomeItemComponent implements OnInit {
  outcome = signal<Outcome>({} as Outcome);
  isLoading = signal(false)

  constructor(
    private route: ActivatedRoute,
    private outcomeService: OutcomeService,
    private router: Router,
    private translateService: TranslateService,
    private employeeService: EmployeeService) {
  }

  ngOnInit(): void {
    const outcome = <Outcome>this.outcomeService.getOutcomeById(this.route.snapshot.params['id'])
    this.outcome.set({
      ...outcome,
      type: this.translateService.translateToFrench(outcome.type) as OutcomeType,
      createdBy: this.employeeService.getEmployeeNameById(outcome.createdBy)
    })
  }


  onDelete(): void {
    this.isLoading.set(true)
    this.outcomeService.deleteOutcome(this.outcome().id as string).subscribe({
      next: () => {
        this.router.navigate(['/depenses']);
      },
      error: (e) => {
        console.error(e);
      },
    });
  }
}
