import {Component, OnInit, signal} from '@angular/core';
import {Employee, EmployeeJob} from "../employee";
import {EmployeeService} from "../employee.service";
import {AuthService} from "../../auth/auth.service";
import {RouterLink} from "@angular/router";
import {NgStyle} from "@angular/common";
import {TranslateService} from "../../shared/translate.service";

@Component({
  selector: 'app-employee-list',
  standalone: true,
  imports: [
    RouterLink,
    NgStyle
  ],
  templateUrl: './employee-list.component.html',
  styleUrl: './employee-list.component.css'
})
export class EmployeeListComponent implements OnInit {
  hasManagementScope = signal(false)
  employees = signal<Employee[]>([]);

  constructor(private employeeService: EmployeeService, private authService: AuthService, private translateService: TranslateService) {
  }

  ngOnInit() {
    this.employeeService.getAllEmployees().subscribe({
      next: (response) => {
        this.employees.set(response.users.map(value => {
          return {
            ...value,
            job: this.translateService.translateToFrench(value.job) as EmployeeJob,
          };
        }));
      },
      error: (e) => {
        console.error(e);
      },
    })
    this.hasManagementScope.set(this.authService.checkIfUserIsAllowed("group:employee"))
  }
}
