import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SessionInfoRequest} from './session-info-requests';
import {SessionInfoResponse} from './session-info-response';
import {catchError, map} from 'rxjs/operators';
import {Subject, Subscription, throwError, timer} from 'rxjs';
import {UtilsService} from "../shared/utils.service";
import {environment} from "../../environments/environment";


type ScopeType = "group:employee" | "group:business_executor" | "group:management";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isAuthenticated = false;
  private sessionInfo!: SessionInfoResponse;
  private refScheduleLogout: Subscription | undefined;
  loginError = new Subject<string>();
  private scopePriority = {
    'group:employee': 1,
    'group:business_executor': 2,
    'group:management': 3
  }


  constructor(private http: HttpClient, private router: Router, private utilsService: UtilsService) {
  }

  checkIfUserIsAllowed(scope: ScopeType): boolean {
    return !!this.sessionInfo.scopes!.find(
      (s) => this.scopePriority[s as ScopeType] >= this.scopePriority[scope]
    )
  }

  get sessionCredentials(): SessionInfoResponse {
    return this.sessionInfo;
  }

  login(userInfo: { username: string, password: string }): void {
    this.http.post<SessionInfoRequest>(`${environment.apiUrl}/session`, {data: userInfo})
      .pipe(map(((responseData): SessionInfoResponse => {
        return {
          ...responseData,
          expirationDate: new Date(Date.parse(responseData.expirationDate)).getTime()
        }
      })))
      .pipe(
        catchError((e: HttpErrorResponse) => {
          let error = '';
          if (e.status === 400 && e.error.detail.includes('User not found with username')) {
            error = "Le nom utilisateur est incorrect.";
          }
          if (e.status === 400 && e.error.detail.includes('Invalid password for user')) {
            error = "Le mot de passe est incorrect.";
          }
          console.error(e)
          console.error(e.error.detail);
          return throwError(() => error);
        }),
      )
      .subscribe({
        next: data => {
          this.isAuthenticated = true;
          this.sessionInfo = {
            accessToken: data.accessToken,
            expirationDate: data.expirationDate,
            scopes: this.utilsService.parseJwt(data.accessToken).scopes,
            username: this.utilsService.parseJwt(data.accessToken).username,
            userId: this.utilsService.parseJwt(data.accessToken).user_id,
          }
          localStorage.setItem('misticd', JSON.stringify(this.sessionInfo));
          this.scheduleLogout();
          this.router.navigate(['/']);
        },
        error: e => {
          console.error(e)
          this.loginError.next(e);
        }
      })
  }

  getStoredCredentials(): void {
    const rawSessionInfo = localStorage.getItem('misticd');
    if (rawSessionInfo) {
      const sessionInfo = JSON.parse(rawSessionInfo);
      if (sessionInfo.expirationDate <= (new Date()).getTime()) {
        this.logout();
      } else {
        this.isAuthenticated = true;
        this.sessionInfo = sessionInfo;
        this.scheduleLogout();
        this.router.navigate(['/']);
      }
    }
  }

  scheduleLogout() {
    this.refScheduleLogout = timer(this.sessionInfo.expirationDate - (new Date()).getTime()).subscribe({
      next: () => {
        this.logout();
      },
      error: (e) => {
        console.error(e);
      },
    })
  }

  checkIfAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  logout(): void {
    this.isAuthenticated = false;
    localStorage.removeItem('misticd');
    this.router.navigate(['/connexion']);
  }
}
