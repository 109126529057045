import {Component} from '@angular/core';
import {FormsModule, NgForm} from "@angular/forms";
import {Router, RouterLink} from "@angular/router";
import {AuthService} from "../../auth/auth.service";
import {UtilsService} from "../../shared/utils.service";
import {ExternalMechanicService} from "../external-mechanic.service";
import {ExternalMechanic} from "../external-mechanic";

@Component({
  selector: 'app-external-mechanic-add',
  standalone: true,
  imports: [
    FormsModule,
    RouterLink,
  ],
  templateUrl: './external-mechanic-add.component.html',
  styleUrl: './external-mechanic-add.component.css'
})
export class ExternalMechanicAddComponent {
  constructor(private router: Router, private externalMechanicService: ExternalMechanicService, private authService: AuthService, private utilsService: UtilsService) {
  }

  onSubmit(f: NgForm) {
    if (f.status === 'VALID') {
      this.externalMechanicService.addExternalMechanic({
        ...this.utilsService.updateKeyNames(f.value, "external-mechanic-add-"),
        specialities: this.formatSpecialities(f),
        additionalComment: f.value["external-mechanic-add-additional-comment"]
      } as Partial<ExternalMechanic>).subscribe({
        next: () => {
          this.router.navigate(['/mecaniciens-externes']);
        },
        error: (e) => {
          console.error(e);
        },
      })
    } else {
      this.utilsService.showFormErrors(f);
    }
  }

  formatSpecialities(f: NgForm): (string | undefined)[] {
    return Object.entries(f.controls).map(([key, v]) => {
        if (key.includes('external-mechanic-speciality-') && v.value) {
          return key.split('-').pop();
        }
        return undefined
      }
    ).filter(Boolean)
  }

}
