<table class="table is-bordered is-hoverable">
  <thead>
    <tr>
      <th><abbr title="title">Intitulé</abbr></th>
      <th>{{ outcome().title }}</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Revenu</td>
      <td>{{ outcome().amount }}</td>
    </tr>
    @if (outcome().type === 'vehicle') {
      <tr>
        <td>Véhicule</td>
        <td>{{ vehicleName() }}</td>
      </tr>
    } @else if (outcome().type === 'salary') {
      <tr>
        <td>Salarié</td>
        <td>{{ employeeName() }}</td>
      </tr>
    }
    <tr>
      <td>Description</td>
      <td>{{ outcome().description }}</td>
    </tr>
    <tr>
      <td>Status</td>
      <td>{{ outcome().isPaid ? 'payé' : 'impayé' }}</td>
    </tr>
    <tr>
      <td>Créer par</td>
      <td>{{ outcome().createdBy }}</td>
    </tr>
    <tr>
      <td>Type</td>
      <td>{{ translatedType() }}</td>
    </tr>
    <tr>
      <td>Crée le</td>
      <td>{{ outcome().creationDate | date: 'dd/MM/YYYY' }}</td>
    </tr>
    <tr>
      <td>Mise à jour le</td>
      <td>{{ outcome().lastUpdateDate | date: 'dd/MM/YYYY' }}</td>
    </tr>
  </tbody>
</table>

<div class="field is-grouped">
  <p class="control">
    <button [routerLink]="'../modification/' + outcome().id" class="button is-link">
      Modifier
    </button>
  </p>
  <p class="control">
    <a routerLink="../">
      <button class="button">Retour</button>
    </a>
  </p>
  <p class="control">
    <a>
      <button
        type="submit"
        id="outcome-item-delete-btn"
        (click)="onDelete()"
        class="button is-danger"
        [disabled]="isLoading()"
        [ngClass]="{ 'is-loading': isLoading() }"
      >
        Supprimer
      </button>
    </a>
  </p>
</div>
