import {Component} from '@angular/core';
import {BasepageComponent} from "../basepage/basepage.component";
import {RouterOutlet} from "@angular/router";

@Component({
  selector: 'app-employee',
  standalone: true,
  imports: [
    BasepageComponent,
    RouterOutlet
  ],
  templateUrl: './employee.component.html',
  styleUrl: './employee.component.css'
})
export class EmployeeComponent {

}
