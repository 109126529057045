<section class="columns is-centered">
  <div class="column is-11">
    <h1 class="title">Edition du vehicule {{ vehicle()?.name }}</h1>
    <form (ngSubmit)="onSubmit(f)" #f="ngForm" autocomplete="on">
      <div class="field">
        <label for="vehicle-edit-name" class="label">Nom</label>
        <div class="control">
          <input
            disabled
            class="input"
            type="text"
            placeholder="Le numero du camion"
            min="1"
            required
            name="vehicle-edit-name"
            id="vehicle-edit-name"
            ngModel
            #number="ngModel"
            [ngModel]="vehicle()?.name"
          />
        </div>
        @if (!number.valid && number.errors?.['required'] && f.submitted) {
          <p class="help is-danger">Le numéro du camion obligatoire</p>
        }
      </div>

      <div class="field">
        <label for="vehicle-edit-price" class="label">Prix d'achat</label>
        <div class="control">
          <input
            disabled
            class="input"
            type="number"
            placeholder="Le numero du camion"
            min="1"
            required
            name="vehicle-edit-price"
            id="vehicle-edit-price"
            ngModel
            #price="ngModel"
            [ngModel]="vehicle()?.price"
          />
        </div>
        @if (!price.valid && price.errors?.['required'] && f.submitted) {
          <p class="help is-danger">Le prix du camion obligatoire</p>
        }
      </div>

      <div class="field">
        <label for="vehicle-edit-buying-date" class="label">Date d'achat</label>
        <div class="control">
          <input
            class="input"
            type="date"
            placeholder="Le numero du camion"
            min="1"
            required
            name="vehicle-edit-buying-date"
            id="vehicle-edit-buying-date"
            ngModel
            #buyindDate="ngModel"
            [ngModel]="vehicle()?.buyingDate"
          />
        </div>
        @if (!buyindDate.valid && buyindDate.errors?.['required'] && f.submitted) {
          <p class="help is-danger">Le date d'achat du camion obligatoire</p>
        }
      </div>

      <div class="field">
        <label for="vehicle-edit-axles" class="label">Chassis</label>
        <div class="control">
          <div class="select">
            <select [ngModel]="vehicle()?.axles" name="vehicle-edit-axles" id="vehicle-edit-axles">
              <option value="6x6">6X6</option>
              <option value="8x4">8X4</option>
              <option value="6x4">6X4</option>
              <option value="6x2">6X2</option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <label for="vehicle-edit-brand" class="label">Marque</label>
        <div class="control">
          <div class="select">
            <select
              [ngModel]="vehicle()?.brand"
              name="vehicle-edit-brand"
              id="vehicle-edit-brand"
              disabled
            >
              <option value="mercedes">Mercedes</option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <label for="vehicle-edit-model" class="label">Model</label>
        <div class="control">
          <div class="select">
            <select
              [ngModel]="vehicle()?.model"
              name="vehicle-edit-model"
              id="vehicle-edit-model"
              disabled
            >
              <option value="actros">Actros</option>
              <option value="arocs">Arocs</option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <label for="vehicle-edit-category" class="label">Category</label>
        <div class="control">
          <div class="select">
            <select
              [ngModel]="vehicle()?.category"
              name="vehicle-edit-category"
              id="vehicle-edit-category"
              disabled
            >
              <option value="truck">camion</option>
              <option value="bus">bus</option>
              <option value="taxi">taxi</option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <div class="control">
          <label for="vehicle-edit-status" class="checkbox">
            <input
              ngModel
              name="vehicle-edit-status"
              id="vehicle-edit-status"
              type="checkbox"
              [ngModel]="vehicle()?.status"
            />
            Le véhicule est il déjà en travail ?
          </label>
        </div>
      </div>

      <div class="field">
        <label for="vehicle-edit-insuranceExpirationDate" class="label"
          >Date d'expiration de l'assurance</label
        >
        <div class="control">
          <input
            class="input"
            type="date"
            placeholder="01/01/2025"
            min="1"
            name="vehicle-edit-insuranceExpirationDate"
            id="vehicle-edit-insuranceExpirationDate"
            ngModel
            [ngModel]="vehicle()?.insuranceExpirationDate"
          />
        </div>
      </div>

      <div class="field">
        <label for="vehicle-edit-technicalControlExpirationDate" class="label"
          >Date d'expiration du contrôle technique</label
        >
        <div class="control">
          <input
            class="input"
            type="date"
            placeholder="01/01/2025"
            min="1"
            name="vehicle-edit-technicalControlExpirationDate"
            id="vehicle-edit-technicalControlExpirationDate"
            [ngModel]="vehicle()?.technicalControlExpirationDate"
            ngModel
          />
        </div>
      </div>

      <div class="field">
        <label for="vehicle-edit-transportAuthorizationExpirationDate" class="label"
          >Date d'expiration de l'autorisation de transport</label
        >
        <div class="control">
          <input
            class="input"
            type="date"
            placeholder="01/01/2025"
            min="1"
            name="vehicle-edit-transportAuthorizationExpirationDate"
            id="vehicle-edit-transportAuthorizationExpirationDate"
            ngModel
            [ngModel]="vehicle()?.transportAuthorizationExpirationDate"
            #buyindDate="ngModel"
          />
        </div>
      </div>

      <div class="field is-grouped">
        <div class="control">
          <button
            id="vehicle-edit-submit"
            type="submit"
            class="button is-link"
            [disabled]="isLoading()"
            [ngClass]="{ 'is-loading': isLoading() }"
          >
            Valider
          </button>
        </div>
        <div class="control">
          <button class="button">Annuler</button>
        </div>
      </div>
    </form>
  </div>
</section>
