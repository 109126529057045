import { DatePipe, NgClass } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TranslateService } from '../../shared/translate.service';
import { ExternalMechanic } from '../external-mechanic';
import { ExternalMechanicService } from '../external-mechanic.service';

@Component({
  selector: 'app-external-mechanic-item',
  standalone: true,
  imports: [DatePipe, RouterLink, NgClass],
  templateUrl: './external-mechanic-item.component.html',
  styleUrl: './external-mechanic-item.component.css',
})
export class ExternalMechanicItemComponent implements OnInit {
  externalMechanic = signal<ExternalMechanic>({} as ExternalMechanic);

  constructor(
    private route: ActivatedRoute,
    private externalMechanicService: ExternalMechanicService,
    private router: Router,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    const externalMechanic = <ExternalMechanic>(
      this.externalMechanicService.getExternalMechanicById(this.route.snapshot.params['id'])
    );
    this.externalMechanic.set({
      ...externalMechanic,
      specialities: externalMechanic.specialities.map(v =>
        this.translateService.translateToFrench(v)
      ),
    });
  }

  isLoading() {}

  onDelete() {
    this.externalMechanicService
      .deleteExternalMechanic(this.route.snapshot.params['id'])
      .subscribe({
        next: () => {
          this.router.navigate(['/mecaniciens-externes']);
        },
        error: e => {
          throw new Error(e);
        },
      });
  }
}
