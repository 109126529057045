<section class="columns">
  <div class="column is-8">
    <table class="table is-bordered is-hoverable">
      <thead>
        <tr>
          <th><abbr title="Title">Titre</abbr></th>
          <th><abbr title="Amount">Depense</abbr></th>
          <th><abbr title="type">Type</abbr></th>
          <th><abbr title="Created by">Crée le</abbr></th>
        </tr>
      </thead>
      <tbody>
        @for (item of outcomes(); track $index) {
          <tr>
            <th>
              <a
                [routerLink]="item.id"
                routerLinkActive="router-link-active"
                [ngStyle]="{ pointerEvents: !hasManagementScope() ? 'none' : null }"
                >{{ item.title }}</a
              >
            </th>
            <td>{{ item.amount }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.creationDate | date: 'dd-MM-yyyy' }}</td>
          </tr>
        }
      </tbody>
    </table>
    @if (!disableCursor()) {
      <div class="control">
        <button type="submit" class="button is-link" (click)="onClickGetNextOutcomes()">
          Suivant
        </button>
      </div>
    }
  </div>
</section>
