import { Component, OnInit, signal } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { BasepageComponent } from '../basepage/basepage.component';
import { UtilsService } from '../shared/utils.service';
import { VehicleService } from '../vehicle/vehicle.service';
import { Summary, SummaryItem, SummaryQuery } from './summary';
import { SummaryService } from './summary.service';

@Component({
  selector: 'app-summary',
  standalone: true,
  imports: [BasepageComponent, FormsModule],
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.css',
})
export class SummaryComponent implements OnInit {
  summary = signal<Partial<Summary>>({});
  summaryByTruck = signal<SummaryItem[]>([]);
  summaryByWeek = signal<SummaryItem[]>([]);
  summaryByTruckByWeek = signal<SummaryItem[][]>([]);
  hasManagementScope = signal(false);

  constructor(
    private summaryService: SummaryService,
    private authService: AuthService,
    private vehicleService: VehicleService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.summaryService.getSummary().subscribe({
      next: response => {
        this._setSummary(response);
      },
    });
    this.hasManagementScope.set(this.authService.checkIfUserIsAllowed('group:management'));
  }

  onSubmit(f: NgForm) {
    if (f.valid) {
      this.summaryService
        .getSummary({
          ...this.utilsService.updateKeyNames(f.value, 'summary-'),
        } as SummaryQuery)
        .subscribe({
          next: response => {
            this._setSummary(response);
            console.log('ok');
          },
          error: error => {
            console.error(error);
          },
        });
    } else {
      this.utilsService.showFormErrors(f);
    }
  }

  _setSummary(response: Summary) {
    this.summary.set(response);
    this.summaryByTruck.set(
      Object.entries(response.summaryByTruck)
        .map(([id, val]) => {
          return { id, ...val };
        })
        .map(summary => {
          return {
            ...summary,
            id: this.vehicleService.getVehicleNameById(summary.id),
          };
        })
    );
    this.summaryByWeek.set(response.summaryByWeek);
    // this.summaryByTruckByWeek.set(response.summaryByTruckByWeek)

    this.summaryByTruckByWeek.set(
      response.summaryByTruckByWeek.map(summaryByWeek => {
        return Object.entries(summaryByWeek)
          .map(([id, summaryTruck]) => {
            return { id, ...summaryTruck } as SummaryItem;
          })
          .map(summary => {
            return {
              ...summary,
              id: this.vehicleService.getVehicleNameById(summary.id as string),
            };
          });
      })
    );
  }
}
