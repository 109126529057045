import {Component, OnInit, signal} from '@angular/core';
import {AuthService} from "../../auth/auth.service";
import {OutcomeService} from "../outcome.service";
import {Outcome} from "../outcome";
import {RouterLink} from "@angular/router";
import {NgStyle} from "@angular/common";
import {EmployeeService} from "../../employee/employee.service";

@Component({
  selector: 'app-outcome-list',
  standalone: true,
  imports: [
    RouterLink,
    NgStyle
  ],
  templateUrl: './outcome-list.component.html',
  styleUrl: './outcome-list.component.css'
})
export class OutcomeListComponent implements OnInit {
  hasManagementScope = signal(false)
  outcomes = signal<Outcome[]>([]);
  private cursor: string | undefined;

  constructor(private outcomeService: OutcomeService, private authService: AuthService, private employeeService: EmployeeService) {
  }

  ngOnInit() {
    this.outcomeService.getAllOutcomes().subscribe({
      next: (response) => {
        this.outcomes.set(response.outcomes.map(outcome => {
          return {
            ...outcome,
            createdBy: this.employeeService.getEmployeeNameById(outcome.createdBy)
          }
        }));
        this.cursor = response.cursor;
      },
      error: (e) => {
        console.error(e);
      },
    })
    this.hasManagementScope.set(this.authService.checkIfUserIsAllowed("group:business_executor"))
  }
}
