<section class="columns is-centered">
  <div class="column is-11">
    <h1 class="title">Ajout d'un mécanicien externe</h1>
    <form (ngSubmit)="onSubmit(f)" #f="ngForm" autocomplete="on">


      <div class="field">
        <label for="external-mechanic-add-firstname" class="label">Prénom</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Pierre"
            required
            name="external-mechanic-add-firstname"
            id="external-mechanic-add-firstname"
            ngModel
            size="300"
            #externalMechanicAddFirstname="ngModel"
          />
        </div>
        @if (!externalMechanicAddFirstname.valid && externalMechanicAddFirstname.errors?.['required'] && f.submitted) {
          <p class="help is-danger">La prénom est obligatoire</p>
        }
      </div>

      <div class="field">
        <label for="external-mechanic-add-lastname" class="label">Nom de famille</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Pasteur"
            required
            name="external-mechanic-add-lastname"
            id="external-mechanic-add-lastname"
            ngModel
            size="300"
            #externalMechanicAddLastname="ngModel"
          />
        </div>
        @if (!externalMechanicAddLastname.valid && externalMechanicAddLastname.errors?.['required'] && f.submitted) {
          <p class="help is-danger">La nom de famille est obligatoire</p>
        }
      </div>


      <div class="field">
        <label for="external-mechanic-add-phoneNumber" class="label">Numéro de téléphone</label>
        <div class="control">
          <input
            class="input"
            type="tel"
            placeholder="e.g: +243 123 456 789"
            required
            name="external-mechanic-add-phoneNumber"
            id="external-mechanic-add-phoneNumber"
            ngModel
            #externalMechanicAddPhoneNumber="ngModel"
            pattern="^(?:\+|)?(?:[0-9] ?-?){6,14}[0-9]$"
          />
        </div>
        @if (!externalMechanicAddPhoneNumber.valid && externalMechanicAddPhoneNumber.errors?.['required'] && f.submitted) {
          <p class="help is-danger">La numéro de téléphone est obligatoire</p>
        } @else if (!externalMechanicAddPhoneNumber.valid && externalMechanicAddPhoneNumber.errors?.['pattern'] && f.submitted) {
          <p class="help is-danger">La numéro de téléphone n'est pas valide</p>
        }
      </div>

      <div class="field">
        <div class="checkboxes">
          <label for="external-mechanic-speciality-motor" class="checkbox">
            <input
              ngModel
              name="external-mechanic-speciality-motor"
              id="external-mechanic-speciality-motor"
              type="checkbox"
              #externalMechanicSpecialityMotor
            />
            Moteur
          </label>
          <label for="external-mechanic-speciality-gearbox" class="checkbox">
            <input
              ngModel
              name="external-mechanic-speciality-gearbox"
              id="external-mechanic-speciality-gearbox"
              type="checkbox"
              #externalMechanicSpecialityGearbox
            />
            Boîte de vitesse
          </label>
          <label for="external-mechanic-speciality-general" class="checkbox">
            <input
              ngModel
              name="external-mechanic-speciality-general"
              id="external-mechanic-speciality-general"
              type="checkbox"
              #externalMechanicSpecialityGeneral
            />
            Général
          </label>
          <label for="external-mechanic-speciality-air" class="checkbox">
            <input
              ngModel
              name="external-mechanic-speciality-air"
              id="external-mechanic-speciality-air"
              type="checkbox"
              #externalMechanicSpecialityAir
            />
            Air
          </label>
        </div>
      </div>

      <div class="field">
        <label for="external-mechanic-add-additional-comment" class="label">Description</label>
        <div class="control">
          <textarea
            class="textarea"
            type="text"
            placeholder="Commentaire sur le mécanicien"
            name="external-mechanic-add-additional-comment"
            id="external-mechanic-add-additional-comment"
            ngModel
            size="300"
            rows="10"
            #externalMechanicAddAdditionalComment="ngModel"
          ></textarea>
        </div>
      </div>


      <div class="field is-grouped">
        <div class="control">
          <button type="submit" class="button is-link">Valider</button>
        </div>
        <p class="control">
          <a routerLink="../">
            <button class="button">Retour</button>
          </a>
        </p>
      </div>
    </form>
  </div>
</section>
