import {Component} from '@angular/core';
import {BasepageComponent} from "../basepage/basepage.component";
import {RouterOutlet} from "@angular/router";

@Component({
  selector: 'app-income',
  standalone: true,
  imports: [
    BasepageComponent,
    RouterOutlet
  ],
  templateUrl: './income.component.html',
  styleUrl: './income.component.css'
})
export class IncomeComponent {

}
