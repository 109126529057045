import { NgClass } from '@angular/common';
import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FormsModule, NgClass],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent implements OnInit, OnDestroy {
  loginError = signal('');
  isLoading = signal(false);

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}
  ngOnInit(): void {
    if (this.authService.checkIfAuthenticated()) {
      this.router.navigate(['/']);
    }
    this.authService.loginError.subscribe(err => {
      this.loginError.set(err);
    });
  }

  ngOnDestroy(): void {
    this.authService.loginError.unsubscribe();
  }

  onSubmit(form: NgForm): void {
    if (form.status === 'VALID') {
      this.isLoading.set(true);
      this.authService.login({
        username: form.value.username,
        password: form.value.password,
      });
    }
  }
}
