import { DatePipe, NgStyle } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { EmployeeService } from '../../employee/employee.service';
import { TranslateService } from '../../shared/translate.service';
import { VehicleService } from '../../vehicle/vehicle.service';
import { Income } from '../income';
import { IncomeService } from '../income.service';

@Component({
  selector: 'app-income-list',
  standalone: true,
  imports: [RouterLink, NgStyle, DatePipe],
  templateUrl: './income-list.component.html',
  styleUrl: './income-list.component.css',
})
export class IncomeListComponent implements OnInit {
  hasManagementScope = signal(false);
  incomes = signal<Income[]>([]);
  cursor = signal<string>('');
  disableCursor = signal<boolean>(false);

  constructor(
    private incomeService: IncomeService,
    private authService: AuthService,
    private employeeService: EmployeeService,
    private vehicleService: VehicleService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.incomeService.getAllIncomes(null).subscribe({
      next: response => {
        this.incomes.set(
          response.incomes.map(v => {
            return {
              ...v,
              createdBy: this.employeeService.getEmployeeNameById(v.createdBy),
              type: this.translateService.translateToFrench(v.type),
            };
          })
        );
        this.cursor.set(response.cursor);
      },
      error: e => {
        throw new Error(e);
      },
    });
    this.hasManagementScope.set(this.authService.checkIfUserIsAllowed('group:business_executor'));
  }

  onClickGetNextIncomes() {
    this.incomeService.getAllIncomes(this.cursor()).subscribe({
      next: response => {
        if (response.incomes.length === 0) {
          this.disableCursor.set(true);
        } else {
          this.incomes.set([
            ...this.incomes(),
            ...response.incomes.map(v => {
              return {
                ...v,
                createdBy: this.employeeService.getEmployeeNameById(v.createdBy),
                type: this.translateService.translateToFrench(v.type),
              };
            }),
          ]);
          this.cursor.set(response.cursor);
        }
      },
      error: e => {
        console.error(e);
        throw new Error(e);
      },
    });
  }
}
