import { NgStyle } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { TranslateService } from '../../shared/translate.service';
import { Employee, EmployeeJob } from '../employee';
import { EmployeeService } from '../employee.service';

@Component({
  selector: 'app-employee-list',
  standalone: true,
  imports: [RouterLink, NgStyle],
  templateUrl: './employee-list.component.html',
  styleUrl: './employee-list.component.css',
})
export class EmployeeListComponent implements OnInit {
  hasManagementScope = signal(false);
  employees = signal<Employee[]>([]);

  constructor(
    private employeeService: EmployeeService,
    private authService: AuthService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.employeeService.getAllEmployees().subscribe({
      next: response => {
        this.employees.set(
          response.users.map(value => {
            return {
              ...value,
              job: this.translateService.translateToFrench(value.job) as EmployeeJob,
            };
          })
        );
      },
      error: e => {
        throw new Error(e);
      },
    });
    this.hasManagementScope.set(this.authService.checkIfUserIsAllowed('group:employee'));
  }
}
