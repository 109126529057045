<section class="columns is-centered">
  <div class="column is-11">
    <h1 class="title">Ajout d'un revenu</h1>
    <form (ngSubmit)="onSubmit(f)" #f="ngForm" autocomplete="on">
      <div class="field">
        <label for="income-add-type" class="label">Type</label>
        <div class="control">
          <div class="select">
            <select
              ngModel
              [ngModel]="defaultIncomeAddValues().type"
              name="income-add-type"
              id="income-add-type"
              #incomeAddType
            >
              <option value="sand_quarry">Carrière</option>
              <option value="merchandise">Marchandise</option>
              <option value="tshikapa">Tshikapa</option>
              <option value="kikwit">Kikwit</option>
              <option value="other">Autre</option>
            </select>
          </div>
        </div>
      </div>

      @if (incomeAddType.value !== 'other') {
        <div class="field">
          <label for="income-add-vehicleId" class="label">Vehicules</label>
          <div class="control">
            <div class="select">
              <select
                ngModel
                [ngModel]="defaultIncomeAddValues().vehicleId"
                required
                name="income-add-vehicleId"
                id="income-add-vehicleId"
              >
                @for (item of defaultIncomeAddValues().vehicleIds; track $index) {
                  <option [value]="item.id">{{ item.name }}</option>
                }
              </select>
            </div>
          </div>
        </div>
      }

      <div class="field">
        <label for="income-add-amount" class="label">Quantité</label>
        <div class="control">
          <input
            class="input"
            type="number"
            placeholder="Quantité de $"
            min="0"
            required
            name="income-add-amount"
            id="income-add-amount"
            ngModel
            #incomeAddAmount="ngModel"
          />
        </div>
        @if (!incomeAddAmount.valid && incomeAddAmount.errors?.['required'] && f.submitted) {
          <p class="help is-danger">La quantité de revenu est obligatoire</p>
        }
      </div>

      <div class="field">
        <label for="income-add-currency" class="label">Devise</label>
        <div class="control">
          <div class="select">
            <select
              [ngModel]="defaultIncomeAddValues().currency"
              name="income-add-currency"
              id="income-add-currency"
            >
              <option value="eur">Euro</option>
              <option value="usd">Dollar</option>
              <option value="cdf">Franc CFA congolais</option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <label for="income-add-description" class="label">Description</label>
        <div class="control">
          <textarea
            class="textarea"
            type="text"
            placeholder="La description des revenues"
            required
            name="income-add-description"
            id="income-add-description"
            ngModel
            size="300"
            #incomeAddDescription="ngModel"
          ></textarea>
        </div>
        @if (
          !incomeAddDescription.valid && incomeAddDescription.errors?.['required'] && f.submitted
        ) {
          <p class="help is-danger">La description est obligatoire</p>
        }
      </div>

      <div class="field">
        <label for="income-add-user" class="label">Crée par</label>
        <div class="control">
          <input
            class="input"
            type="text"
            required
            name="income-add-user"
            id="income-add-user"
            ngModel
            disabled
            [ngModel]="defaultUsername()"
            #incomeAddUser="ngModel"
          />
        </div>
        @if (!incomeAddUser.valid && incomeAddUser.errors?.['required'] && f.submitted) {
          <p class="help is-danger">Un nom utilisateur est obligatoire</p>
        }
      </div>

      <div class="field">
        <label for="income-add-creationDate" class="label">Date</label>
        <div class="control">
          <input
            class="input"
            type="date"
            min="1"
            name="income-add-creationDate"
            id="income-add-creationDate"
            ngModel
          />
        </div>
      </div>

      <div class="field is-grouped">
        <div class="control">
          <button type="submit" class="button is-link">Valider</button>
        </div>
        <p class="control">
          <a routerLink="../">
            <button class="button">Retour</button>
          </a>
        </p>
      </div>
    </form>
  </div>
</section>
