import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { BasepageComponent } from '../basepage/basepage.component';

@Component({
  selector: 'app-mechanical-inventory',
  standalone: true,
  imports: [BasepageComponent, RouterOutlet],
  templateUrl: './mechanical-inventory.component.html',
  styleUrl: './mechanical-inventory.component.css',
})
export class MechanicalInventoryComponent {}
