import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  constructor() {
  }

  translateToFrench(word: string): string {
    return {
      "tipper": "benne",
      "truck": "camion",
      "sand_quarry": "carrière de sable",
      "salary": "salaire",
      "vehicle": "véhicule",

      // jobs
      "driver": "chauffeur",
      "manager": "gestionnaire",
      "mechanic": "mécanicien",
      "controller": "gérant",

      // mechanical
      "mechanical_item": "Piece Mécanique",
      "motor": "moteur",
      "gearbox": "boite de vitesse",

      // scope
      "management": "direction",
      "business_executor": "cercle rapproché",

    }[word] || word
  }
}
