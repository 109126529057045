import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { ExternalPartnerShop, ExternalPartnerShopList } from './external-partner-shop';

@Injectable({
  providedIn: 'root',
})
export class ExternalPartnerShopService {
  private apiUrl = `${environment.apiUrl}/external_partner_shops`;
  private currentPartnerShopList: ExternalPartnerShopList = {
    externalPartnerShops: [],
    cursor: '',
  };

  constructor(private http: HttpClient) {}

  getPartnerShops(): Observable<ExternalPartnerShopList> {
    return this.http.get<ExternalPartnerShopList>(this.apiUrl).pipe(
      catchError(this.handleError),
      tap((data: ExternalPartnerShopList) => {
        this.currentPartnerShopList = data;
      })
    );
  }

  getPartnerShop(id: string): ExternalPartnerShop {
    const ExternalPartnerShop = this.currentPartnerShopList.externalPartnerShops.find(
      p => p.id === id
    );
    if (!ExternalPartnerShop) {
      throw new Error(`External Partner Shop with id ${id} not found.`);
    }
    return ExternalPartnerShop;
  }

  addPartnerShop(
    partnerShop: Partial<ExternalPartnerShop>
  ): Observable<Partial<ExternalPartnerShop>> {
    return this.http
      .post<Partial<ExternalPartnerShop>>(this.apiUrl, { data: partnerShop })
      .pipe(catchError(this.handleError));
  }

  updatePartnerShop(
    id: string,
    partnerShop: Partial<ExternalPartnerShop>
  ): Observable<Partial<ExternalPartnerShop>> {
    return this.http
      .put<Partial<ExternalPartnerShop>>(`${this.apiUrl}/${id}`, { data: partnerShop })
      .pipe(catchError(this.handleError));
  }

  deletePartnerShop(id: string): Observable<null> {
    return this.http.delete<null>(`${this.apiUrl}/${id}`);
  }

  private handleError(e: HttpErrorResponse) {
    // console.error(e.error);
    return throwError(() => e);
  }
}
