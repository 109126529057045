import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { jwtDecode } from 'jwt-decode';
import { StringToStringDictionary } from './commonType/string-to-string-dictionary';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  updateKeyNames(formValues: object, keyToRemove: string): StringToStringDictionary {
    const newFormValues: StringToStringDictionary = {};
    for (const [key, value] of Object.entries(formValues)) {
      newFormValues[`${key.replace(keyToRemove, '')}`] = value;
    }
    return newFormValues;
  }

  parseJwt(token: string): { scopes: string[]; username: string; user_id: string } {
    return jwtDecode(token);
  }

  parseDateFromApi(value: string | undefined | null): string {
    if (typeof value === 'string' && value) {
      return new Date(value).toLocaleDateString().split('/').reverse().join('-');
    }
    return '';
  }

  showFormErrors(f: NgForm): void {
    // Iterate over the controls in the form
    Object.keys(f.controls).forEach(key => {
      const control = f.controls[key]; // Get the control directly
      const controlErrors = control.errors; // Access the errors property

      if (controlErrors != null) {
        // Iterate over the errors for the control
        Object.keys(controlErrors).forEach(keyError => {
          console.error(
            'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
            controlErrors[keyError]
          );
        });
      }
    });
  }
}
