<table class="table is-bordered is-hoverable">
  <thead>
    <tr>
      <th><abbr title="title">Nom Utilisateur</abbr></th>
      <th>{{ employee().username }}</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Prénom</td>
      <td>{{ employee().firstname }}</td>
    </tr>
    <tr>
      <td>Nom de famille</td>
      <td>{{ employee().lastname }}</td>
    </tr>
    <tr>
      <td>Adresse</td>
      <td>{{ employee().adress }}</td>
    </tr>
    <tr>
      <td>Numéro de téléphone</td>
      <td>{{ employee().phoneNumber }}</td>
    </tr>
    <tr>
      <td>Poste</td>
      <td>{{ employee().job }}</td>
    </tr>
    <tr>
      <td>Groupe</td>
      <td>{{ employee().group }}</td>
    </tr>
    <tr>
      <td>Crée le</td>
      <td>{{ employee().creationDate | date: 'dd/MM/YYYY' }}</td>
    </tr>
    <tr>
      <td>Compte actif</td>
      <td>
        <span
          [class]="
            employee().isActive ? 'has-text-success gg-check-o' : 'has-text-danger gg-close-o'
          "
        >
        </span>
      </td>
    </tr>

    <tr>
      <td>Information Additionel</td>
      <td>{{ employee().additionalComment }}</td>
    </tr>
  </tbody>
</table>

<div class="field is-grouped">
  <p class="control">
    <button [routerLink]="'../modification/' + employee().id" class="button is-link">
      Modifier
    </button>
  </p>
  <p class="control">
    <a routerLink="../">
      <button class="button">Retour</button>
    </a>
  </p>
  <p class="control">
    <a>
      <button
        type="submit"
        id="employee-item-delete-btn"
        (click)="onDelete()"
        class="button is-danger"
        [disabled]="isLoading()"
        [ngClass]="{ 'is-loading': isLoading() }"
      >
        Supprimer
      </button>
    </a>
  </p>
</div>
