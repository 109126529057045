<table class="table is-bordered is-hoverable">
  <thead>
  <tr>
    <th><abbr title="title">Nom</abbr></th>
    <th>{{ externalMechanic().firstname }} {{ externalMechanic().lastname }}</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td>Téléphone</td>
    <td>{{ externalMechanic().phoneNumber }}</td>
  </tr>
  <tr>
    <td>Spécialités</td>
    <td>
      @for (item of externalMechanic().specialities; track $index) {
        <li>{{ item }}</li>
      }
    </td>
  </tr>
  <tr>
    <td>Commentaire additionnel</td>
    <td>{{ externalMechanic().additionalComment }}</td>
  </tr>
  <tr>
    <td>Crée le</td>
    <td>{{ externalMechanic().creationDate | date:'dd/MM/YYYY' }}</td>
  </tr>
  <tr>
    <td>Mise à jour le</td>
    <td>{{ externalMechanic().lastUpdateDate | date:'dd/MM/YYYY' }}</td>
  </tr>
  </tbody>
</table>

<div class="field is-grouped">
  <p class="control">
    <button
      [routerLink]="'../modification/' + externalMechanic().id"
      class="button is-link"
    >
      Modifier
    </button>
  </p>
  <p class="control">
    <a routerLink="../">
      <button class="button">Retour</button>
    </a>
  </p>
  <p class="control">
    <a
    >
      <button type="submit" id="external-mechanic-item-delete-btn" (click)="onDelete()" class="button is-danger"
              [disabled]="isLoading()"
              [ngClass]="{'is-loading': isLoading()}">
        Supprimer
      </button>
    </a
    >
  </p>
</div>
