import {Component, OnInit, signal} from '@angular/core';
import {VehicleService} from '../vehicle.service';
import {Vehicle, VehicleType} from '../vehicle';
import {RouterLink} from '@angular/router';
import {TranslateService} from "../../shared/translate.service";
import {AuthService} from "../../auth/auth.service";
import {NgClass, NgStyle} from "@angular/common";


@Component({
  selector: 'app-vehicle-list',
  standalone: true,
  imports: [RouterLink, NgClass, NgStyle],
  templateUrl: './vehicle-list.component.html',
  styleUrl: './vehicle-list.component.css',
})
export class VehicleListComponent implements OnInit {
  hasManagementScope = signal(false)

  constructor(private vehicleService: VehicleService, private translateService: TranslateService, private authService: AuthService) {
  }

  vehicles = signal<Vehicle[]>([]);
  private cursor: string = '';

  ngOnInit(): void {
    this.vehicleService.getAllVehicles().subscribe({
      next: (response) => {
        this.vehicles.set(response.vehicles.map(vehicle => {
          return {
            ...vehicle,
            type: this.translateService.translateToFrench(vehicle.type) as VehicleType,
            category: this.translateService.translateToFrench(vehicle.category),
          };
        }));
        this.cursor = response.cursor;
      },
      error: (e) => {
        console.error(e);
      },
    });
    this.hasManagementScope.set(this.authService.checkIfUserIsAllowed("group:management"))

  }


}
