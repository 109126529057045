import {ApplicationConfig, provideZoneChangeDetection} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {provideHttpClient, withFetch, withInterceptorsFromDi} from '@angular/common/http';
import {bearerTokenInterceptorProvider} from './auth/bearer-token.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    bearerTokenInterceptorProvider,
    // provideClientHydration(withEventReplay()),
    provideZoneChangeDetection({eventCoalescing: true})
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: true,
    //     logErrors: true
    //   }),
    // }, {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {
    //   },
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // }
  ],
};
