<div class="columns">
  <aside class="menu column is-offset-1 is-11">
    <p class="menu-label">General</p>
    <ul class="menu-list">
      <li><a routerLinkActive="is-active" routerLink="">Page d'accueil</a></li>
    </ul>
    <p class="menu-label">Administration</p>
    <ul class="menu-list">
      <li>
        <a>Gestion des effectifs</a>
        <ul>
          <li><a routerLink="/employees">Liste d'employées</a></li>
          <li><a routerLink="/employees/ajout">Ajouter un employée</a></li>
        </ul>
      </li>
      <li>
        <a>Véhicules</a>
        <ul>
          <li><a routerLink="/vehicules">Liste des véhicule</a></li>
          @if (hasManagementScope()) {
            <li><a routerLink="/vehicules/ajout">Ajout d'un véhicule</a></li>
          }
        </ul>
      </li>
    </ul>
    <p class="menu-label">Finances</p>
    <ul class="menu-list">
      <li>
        <a>Revenus</a>
        <ul>
          <li><a routerLink="/revenues">Liste des revenus</a></li>
          <li><a routerLink="/revenues/ajout">Ajouter un revenus</a></li>
        </ul>
      </li>
      <li>
        <a>Dépenses</a>
        <ul>
          <li><a routerLink="/depenses">Liste des dépenses</a></li>
          <li><a routerLink="/depenses/ajout">Ajouter une dépense</a></li>
        </ul>
      </li>
      <li><a routerLink="/bilan">Bilan</a></li>
    </ul>
    <p class="menu-label">Autres</p>
    <ul class="menu-list">
      <li>
        <a>Mecaniciens Externes</a>
        <ul>
          <li><a routerLink="/mecaniciens-externes">Liste des mécaniciens externes</a></li>
          <li><a routerLink="/mecaniciens-externes/ajout">Ajouter un mécanicien externe</a></li>
        </ul>
      </li>
      <li>
        <a>Partenaires Commercants</a>
        <ul>
          <li><a routerLink="/partenaires-commercants">Liste des Partenaires commercants</a></li>
          <li>
            <a routerLink="/partenaires-commercants/ajout">Ajouter un partenaire commercant</a>
          </li>
        </ul>
      </li>
      <li>
        <a>Inventaire</a>
        <ul>
          <li><a routerLink="/inventaire">Liste des pièces dans l'ivnentaire</a></li>
          <li><a routerLink="/inventaire/ajout">Ajout d'une pièce dans l'inventaire</a></li>
        </ul>
      </li>
    </ul>
  </aside>
</div>
