import {Component, OnInit, signal} from '@angular/core';
import {VehicleService} from '../vehicle.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Vehicle} from '../vehicle';
import {FormsModule, NgForm} from '@angular/forms';
import {UtilsService} from '../../shared/utils.service';
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-vehicle-edit',
  standalone: true,
  imports: [FormsModule, NgClass],
  templateUrl: './vehicle-edit.component.html',
  styleUrl: './vehicle-edit.component.css',
})
export class VehicleEditComponent implements OnInit {
  vehicle = signal<Vehicle | null>(null);
  isLoading = signal(false)

  constructor(
    private vehicleService: VehicleService,
    private route: ActivatedRoute,
    private utilSerivce: UtilsService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    if (
      this.route.snapshot.params['id'] ===
      this.vehicleService.getCurrentVehicle()?.id
    ) {
      this.updateVehicle(this.vehicleService.getCurrentVehicle());
    } else {
      this.vehicleService
        .getVehicleById(this.route.snapshot.params['id'])
        .subscribe({
          next: (response) => {
            this.updateVehicle(response)
          },
          error: (e) => {
            console.error(e);
          },
        });
    }
  }

  updateVehicle(vehicle: Vehicle | null): void {
    if (vehicle) {
      vehicle.buyingDate = new Date(vehicle.buyingDate)
        .toLocaleDateString()
        .split('/')
        .reverse()
        .join('-');
      this.vehicle.set(vehicle);
    }
  }

  get vehicleNumber(): number | undefined {
    const vehicleNumber = this.vehicle()?.name.split('').slice(-1)[0];
    if (typeof vehicleNumber === 'string') return parseInt(vehicleNumber, 10);
    return undefined;
  }

  onSubmit(f: NgForm): void {
    if (f.status === 'VALID') {
      this.isLoading.set(true)
      this.vehicleService
        .updateVehicle({
          ...this.utilSerivce.updateKeyNames(f.value, 'vehicle-edit-'),
          buyingDate: f.value['vehicle-edit-buying-date'],
          id: this.vehicle()?.id,
        } as Vehicle)
        .subscribe({
          next: () => {
            this.router.navigate(['/vehicules']);
          },
          error: (e) => {
            console.error(e);
          },
        });
    }
  }
}
