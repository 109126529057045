import { Component, OnInit } from '@angular/core';
import { EmployeeService } from '../employee/employee.service';
import { FooterComponent } from '../shared/footer/footer.component';
import { MenuComponent } from '../shared/menu/menu.component';
import { NavComponent } from '../shared/nav/nav.component';
import { VehicleService } from '../vehicle/vehicle.service';

@Component({
  selector: 'app-basepage',
  standalone: true,
  imports: [NavComponent, FooterComponent, MenuComponent],
  templateUrl: './basepage.component.html',
  styleUrl: './basepage.component.css',
})
export class BasepageComponent implements OnInit {
  constructor(
    private employeeService: EmployeeService,
    private vehicleService: VehicleService
  ) {}

  ngOnInit(): void {
    this.employeeService.initialFetchEmployees();
    this.vehicleService.initialFetchVehicles();
  }
}
