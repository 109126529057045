import { DatePipe, NgClass } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { EmployeeService } from '../../employee/employee.service';
import { TranslateService } from '../../shared/translate.service';
import { VehicleService } from '../../vehicle/vehicle.service';
import { Outcome, OutcomeType } from '../outcome';
import { OutcomeService } from '../outcome.service';

@Component({
  selector: 'app-outcome-item',
  standalone: true,
  imports: [DatePipe, RouterLink, NgClass],
  templateUrl: './outcome-item.component.html',
  styleUrl: './outcome-item.component.css',
})
export class OutcomeItemComponent implements OnInit {
  outcome = signal<Outcome>({} as Outcome);
  isLoading = signal(false);
  employeeName = signal('');
  vehicleName = signal('');
  translatedType = signal<OutcomeType>('vehicle');

  constructor(
    private route: ActivatedRoute,
    private outcomeService: OutcomeService,
    private vehicleService: VehicleService,
    private router: Router,
    private translateService: TranslateService,
    private employeeService: EmployeeService
  ) {}

  ngOnInit(): void {
    const outcome = <Outcome>this.outcomeService.getOutcomeById(this.route.snapshot.params['id']);
    this.outcome.set({
      ...outcome,
      createdBy: this.employeeService.getEmployeeNameById(outcome.createdBy),
    });
    this.translatedType.set(this.translateService.translateToFrench(outcome.type) as OutcomeType);
    if (outcome.userId) {
      this.employeeName.set(this.employeeService.getEmployeeNameById(outcome.userId));
    } else if (outcome.vehicleId) {
      this.vehicleName.set(this.vehicleService.getVehicleNameById(outcome.vehicleId));
    }
  }

  onDelete(): void {
    this.isLoading.set(true);
    this.outcomeService.deleteOutcome(this.outcome().id as string).subscribe({
      next: () => {
        this.router.navigate(['/depenses']);
      },
      error: e => {
        throw new Error(e);
      },
    });
  }
}
