import { NgClass } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-nav',
  standalone: true,
  imports: [RouterLink, NgClass],
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.css',
})
export class NavComponent implements OnInit {
  currentPath = signal<Fragments[]>([]);

  constructor(private router: Router) {}

  ngOnInit() {
    this.updateCurrentPath(this.router.url);
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((data: NavigationEnd) => {
        // const event: NavigationEnd = data as NavigationEnd
        this.updateCurrentPath(data.url);
      });
  }

  updateCurrentPath(url: string) {
    const fragments: Fragments[] = [
      {
        fragment: '/',
        label: 'Mistic D',
      },
    ];
    if (url && url !== '/') {
      const urlSplit = url.split('/');
      urlSplit.forEach((fragment, index) => {
        if (index !== 0) {
          fragments.push({
            fragment: `/${fragment}`,
            label: fragment,
            active: index > 1,
          });
        }
      });
    }
    this.currentPath.set(fragments);
  }
}

export interface Fragments {
  fragment: string;
  label: string;
  active?: boolean;
}
