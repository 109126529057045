import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { BasepageComponent } from '../basepage/basepage.component';

@Component({
  selector: 'app-external-mechanic',
  standalone: true,
  imports: [BasepageComponent, RouterOutlet],
  templateUrl: './external-mechanic.component.html',
  styleUrl: './external-mechanic.component.css',
})
export class ExternalMechanicComponent {}
