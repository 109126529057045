import {Injectable} from '@angular/core';
import {Income, IncomeList} from "./income";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import {environment} from "../../environments/environment";
import {catchError, Observable, tap, throwError} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class IncomeService {
  currentIncomes: IncomeList | undefined;

  constructor(private http: HttpClient) {
  }

  getIncomeById(id: string): Income | undefined {
    return this.currentIncomes?.incomes.find(v => v.id === id)
  }

  getAllIncomes(): Observable<IncomeList> {
    return this.http
      .get<IncomeList>(`${environment.apiUrl}/incomes`)
      .pipe(
        catchError((e: HttpErrorResponse) => {
          // console.error(e.error);
          return throwError(() => e);
        })
      ).pipe(
        tap((data: IncomeList) => {
          this.currentIncomes = data;
        })
      );
  }

  addIncome(income: Partial<Income>): Observable<Partial<Income>> {
    return this.http
      .post<Partial<Income>>(`${environment.apiUrl}/incomes`, {data: [income]})
      .pipe(
        catchError((e: HttpErrorResponse) => {
          // console.error(e.error);
          return throwError(() => e);
        })
      )
  }

  updateIncome(income: Partial<Income>): Observable<Partial<Income>> {
    return this.http
      .put<Income>(`${environment.apiUrl}/incomes/${income.id}`, {data: income})
      .pipe(
        catchError((e: HttpErrorResponse) => {
          // console.error(e.error);
          return throwError(() => e);
        })
      )
  }

  deleteIncome(id: string): Observable<null> {
    /**
     Deletes the income with the given ID.
     @param id the ID of the income to delete
     */
    return this.http.delete<null>(`${environment.apiUrl}/incomes/${id}`).pipe(
      catchError((e: HttpErrorResponse) => {
        // console.error(e.error);
        return throwError(() => e);
      })
    );
  }
}
