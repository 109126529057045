import {Component} from '@angular/core';
import {FormsModule, NgForm} from "@angular/forms";
import {Router, RouterLink} from "@angular/router";
import {UtilsService} from "../../shared/utils.service";
import {ExternalPartnerShopService} from "../external-partner-shop.service";
import {ExternalPartnerShop} from "../external-partner-shop";

@Component({
  selector: 'app-external-partner-shop-add',
  standalone: true,
  imports: [
    FormsModule,
    RouterLink
  ],
  templateUrl: './external-partner-shop-add.component.html',
  styleUrl: './external-partner-shop-add.component.css'
})
export class ExternalPartnerShopAddComponent {
  constructor(private utilsService: UtilsService, private externalPartnerShopService: ExternalPartnerShopService, private router: Router) {
  }

  onSubmit(f: NgForm) {
    if (f.status === 'VALID') {
      this.externalPartnerShopService.addPartnerShop({
        ...this.utilsService.updateKeyNames(f.value, 'external-partner-shop-add-'),
      } as Partial<ExternalPartnerShop>).subscribe({
        next: () => {
          // Redirect to the list of external partner shops
          this.router.navigate(['/partenaires-commercants']);
        },
        error: (e) => {
          console.error(e);
        }
      });
    } else {
      // Handle invalid form submission
      this.utilsService.showFormErrors(f);
    }
  }
}
