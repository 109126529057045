<table class="table is-bordered is-hoverable">
  <thead>
    <tr>
      <th><abbr title="title">Nom</abbr></th>
      <th>{{ externalPartnerShop().name }}</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Adresse</td>
      <td>{{ externalPartnerShop().location }}</td>
    </tr>
    <tr>
      <td>Nom du contact</td>
      <td>
        {{ externalPartnerShop().primaryContactFirstname }}
        {{ externalPartnerShop().primaryContactLastname }}
      </td>
    </tr>
    <tr>
      <td>Numéro de téléphone</td>
      <td>{{ externalPartnerShop().primaryContactPhoneNumber }}</td>
    </tr>
    <tr>
      <td>Crée le</td>
      <td>{{ externalPartnerShop().creationDate | date: 'dd/MM/YYYY' }}</td>
    </tr>
    <tr>
      <td>Mise à jour le</td>
      <td>{{ externalPartnerShop().lastUpdateDate | date: 'dd/MM/YYYY' }}</td>
    </tr>
  </tbody>
</table>

<div class="field is-grouped">
  <p class="control">
    <button [routerLink]="'../modification/' + externalPartnerShop().id" class="button is-link">
      Modifier
    </button>
  </p>
  <p class="control">
    <a routerLink="../">
      <button class="button">Retour</button>
    </a>
  </p>
  <p class="control">
    <a>
      <button
        type="submit"
        id="external-mechanic-item-delete-btn"
        (click)="onDelete()"
        class="button is-danger"
        [disabled]="isLoading()"
        [ngClass]="{ 'is-loading': isLoading() }"
      >
        Supprimer
      </button>
    </a>
  </p>
</div>
