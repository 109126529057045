<section class="columns">
  <div class="column is-8">
    <table class="table is-bordered is-hoverable">
      <thead>
        <tr>
          <th><abbr title="Name">Nom</abbr></th>
          <th><abbr title="PhoneNumber">Téléphone</abbr></th>
          <th><abbr title="Specialities">Spécialité</abbr></th>
        </tr>
      </thead>
      <tbody>
        @for (item of externalMechanics(); track $index) {
          <tr>
            <th>
              <a
                [routerLink]="item.id"
                routerLinkActive="router-link-active"
                [ngStyle]="{ pointerEvents: !hasEmployeeScope() ? 'none' : null }"
              >
                {{ item.firstname }} {{ item.lastname }}</a
              >
            </th>
            <td>{{ item.phoneNumber }}</td>
            <td>
              @for (item of item.specialities; track $index) {
                <li>{{ item }}</li>
              }
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</section>
