import { Component, OnInit, signal } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { UtilsService } from '../../shared/utils.service';
import { ExternalPartnerShop } from '../external-partner-shop';
import { ExternalPartnerShopService } from '../external-partner-shop.service';

@Component({
  selector: 'app-external-partner-shop-edit',
  standalone: true,
  imports: [FormsModule, RouterLink],
  templateUrl: './external-partner-shop-edit.component.html',
  styleUrl: './external-partner-shop-edit.component.css',
})
export class ExternalPartnerShopEditComponent implements OnInit {
  externalPartnerShop = signal<ExternalPartnerShop>({} as ExternalPartnerShop);

  constructor(
    private externalPartnerShopService: ExternalPartnerShopService,
    private route: ActivatedRoute,
    private router: Router,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.externalPartnerShop.set(
      this.externalPartnerShopService.getPartnerShop(this.route.snapshot.params['id'])
    );
  }

  onSubmit(f: NgForm) {
    if (f.status === 'VALID') {
      this.externalPartnerShopService
        .updatePartnerShop(this.externalPartnerShop().id, {
          ...this.utilsService.updateKeyNames(f.value, 'external-partner-shop-edit-'),
        } as Partial<ExternalPartnerShop>)
        .subscribe({
          next: () => {
            this.router.navigate(['/partenaires-commercants', this.externalPartnerShop().id]);
          },
          error: e => {
            throw new Error(e);
          },
        });
    } else {
      // Handle invalid form submission
      this.utilsService.showFormErrors(f);
    }
  }
}
