import { Component, OnInit, signal } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TranslateService } from '../../shared/translate.service';
import { UtilsService } from '../../shared/utils.service';
import { Employee } from '../employee';
import { EmployeeService } from '../employee.service';

@Component({
  selector: 'app-employee-edit',
  standalone: true,
  imports: [FormsModule, RouterLink],
  templateUrl: './employee-edit.component.html',
  styleUrl: './employee-edit.component.css',
})
export class EmployeeEditComponent implements OnInit {
  employee = signal<Employee>({} as Employee);
  defautEmployeeValues = signal({
    groups: ['employee', 'business_executor', 'management'].map(v => {
      return {
        id: v,
        value: this.translateService.translateToFrench(v),
      };
    }),
    jobs: ['driver', 'pdg', 'dg', 'manager', 'controller', 'mechanic'].map(v => {
      return {
        id: v,
        value: this.translateService.translateToFrench(v),
      };
    }),
  });

  constructor(
    private employeeService: EmployeeService,
    private utilsService: UtilsService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.employeeService.getEmployeeById(this.route.snapshot.params['id']).subscribe({
      next: response => {
        this.employee.set(response);
      },
      error: e => {
        throw new Error(e);
      },
    });
  }

  onSubmit(f: NgForm) {
    if (f.status === 'VALID') {
      this.employeeService
        .updateEmployee({
          ...this.utilsService.updateKeyNames(f.value, 'employee-edit-'),
          id: this.employee().id,
        } as Partial<Employee>)
        .subscribe({
          next: () => {
            this.router.navigate(['/employees']);
          },
          error: e => {
            throw new Error(e);
          },
        });
    }
  }
}
