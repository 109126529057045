<div class="container">
  <nav class="breadcrumb level has-arrow-separator" aria-label="breadcrumbs">
    <div class="level-item has-text-centered">
      <ul>
        @for (item of currentPath(); track $index) {
          <li [ngClass]="{'is-active': item.active}">
            <a [routerLink]="item.fragment">{{ item.label }}</a>
          </li>
        }
      </ul>
    </div>
  </nav>
</div>
